module.exports = {
  open: function() {
    $('#uploadSpreadsheetModal form')[0].reset();
    $('#uploadSpreadsheetModal .alert-success').hide();
    $('#uploadSpreadsheetModal .alert-danger').hide();
    $('#uploadSpreadsheetModal .progress-bar').css('width',  '0%');
    $('#uploadSpreadsheetModal').modal('show');
  },
  init: function() {
    $('#uploadSpreadsheetModal-upload').on('click', function(e) {
      $('#uploadSpreadsheetModal .progress-bar').css('width',  '0%');
      $('#uploadSpreadsheetModal .alert-success').hide();
      $('#uploadSpreadsheetModal .alert-danger').hide();
      var which = $(this).attr('id');
      e.preventDefault();
      var validator = $('#uploadSpreadsheetModal-form').validate();
      if(validator.form()) {
        var data = new FormData();
        data.append('import_source', 'otis');
        $.each($('#uploadSpreadsheetModal-spreadsheet')[0].files, function(i, file) {
            data.append('spreadsheet', file);
        });
        var progress = function(e) {
          if(e.lengthComputable){
              var max = e.total;
              var current = e.loaded;
      
              var Percentage = (current * 100)/max;
              $('#uploadSpreadsheetModal .progress-bar').css('width', Percentage + '%');
      
              if(Percentage >= 100)
              {
                 // process completed  
              }
          } 
        }
        jQuery.ajax({
          url: '/data/uploadSpreadsheet',
          data: data,
          cache: false,
          contentType: false,
          processData: false,
          type: 'POST',
          xhr: function() {
            var myXhr = $.ajaxSettings.xhr();
            if(myXhr.upload){
                myXhr.upload.addEventListener('progress',progress, false);
            }
            return myXhr;
          },
          success: function(data){
            $('#uploadSpreadsheetModal .status-window').html('<p>Processing spreadsheet...</p>');
            $('#uploadSpreadsheetModal .status-window').show();
            // set up a task to help make sure the process doesn't hang
            let statusCheckInterval = setInterval(function() {
              $.ajax({
                url: '/data/checkSessionStatus',
                method: 'post',
                data: {
                  session_id: data.unique_id
                },
                success: function(xhr) {
                  
                }
              })
            }, 10000);
            // worst case scenario, clear that interval after a few minutes
            setTimeout(function() {
              if(statusCheckInterval) {
                clearInterval(statusCheckInterval);
              }
            }, 180000)
            window.socketIO.on('/spreadsheetUpdates/' + data.unique_id, function (data) {
              // console.log(data);
              if(data.status == 'done') {
                clearInterval(statusCheckInterval);
                statusCheckInterval = false;
                window.socketIO.removeAllListeners('/spreadsheetUpdates/' + data.unique_id);
                $('#uploadSpreadsheetModal .status-window').hide();
                if(data.error) {
                  if(data.hasOwnProperty('errorReportURL')) {
                    $('#uploadSpreadsheetModal .alert-danger').html('<i class="fa fa-ban-circle"></i> There were errors in your upload. Please download the <a href="'+data.errorReportURL+'" class="alert-link" target="_blank">error report</a> and submit again. ')
                  } else {
                    $('#uploadSpreadsheetModal .alert-danger').html('<i class="fa fa-ban-circle"></i> There were errors in your upload. Please fix them and submit again. ')
                  }
                  $('#uploadSpreadsheetModal .alert-success').hide();
                  $('#uploadSpreadsheetModal .alert-danger').show();
                } else {
                  $('#uploadSpreadsheetModal form')[0].reset();
                  $('#uploadSpreadsheetModal .alert-success').show();
                  $('#uploadSpreadsheetModal .alert-danger').hide();
                  $('.update-filter').trigger('click');
                }
              } else {
                $('#uploadSpreadsheetModal .status-window').append('<p>' + data.message + '</p>');
                $("#uploadSpreadsheetModal .status-window").scrollTop($("#uploadSpreadsheetModal .status-window")[0].scrollHeight);
              }
            });
           
          }
        });
      }
    });
  }
}