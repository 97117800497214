// $(document).ready(function(){
module.exports = {
  init: function() {
    try {
      $('#addTracksBulkModal-dropzone').dropzone({ 
        url: "/track/upload",
        parallelUploads: 4,
        acceptedFiles: 'audio/*',
        dictDefaultMessage: 'Drag tracks here to upload',
        paramName: 'tracks',
        // uploadMultiple: true
        addRemoveLinks: true,
        params: {
          source: 'bulkUpload'
        },
        init: function() {
          this.on('complete', function() {
            if($('.update-filter').length > 0) {
              $('.update-filter').trigger('click');
            }
          });
        }
      });
    } catch(e) {
      
    }
  },
  onload: function() {
     
  }
};

