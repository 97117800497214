var router = {
  pages: {
    '/c/': {
      title: 'Catalog',
      bodyClass: 'page-catalog',
      jsFile: 'outside',
      sidebar: false
    },
    '/dashboard' : {
      title: 'Dashboard',
      bodyClass: 'page-dashboard',
      jsFile: 'home'
    },
    '/my-tracks' : {
      title: 'My Tracks',
      bodyClass: 'page-list',
      jsFile: 'listPage'
    },
    '/my-favorites' : {
      title: 'My Favorites',
      bodyClass: 'page-list',
      jsFile: 'listPage'
    },
    '/my-orders' : {
      title: 'My Orders',
      bodyClass: 'page-list',
      jsFile: 'listPage'
    },
    '/my-albums' : {
      title: 'My Albums',
      bodyClass: 'page-list',
      jsFile: 'listPage'
    },
    '/my-playlists' : {
      title: 'My Playlists',
      bodyClass: 'page-list',
      jsFile: 'listPage'
    },
    '/my-collections' : {
      title: 'My Collections',
      bodyClass: 'page-list',
      jsFile: 'listPage'
    },
    '/my-requests' : {
      title: 'My Requests',
      bodyClass: 'page-list',
      jsFile: 'listPage'
    },
    '/manage-assets' : {
      title: 'Manage Assets',
      bodyClass: 'page-manage-audio',
      jsFile: 'manageAudioPage'
    },
    '/account/profile' : {
      title: 'Edit Profile',
      bodyClass: 'page-form page-editProfile',
      jsFile: 'formPage'
    },
    '/direct-licensing' : {
      title: 'Direct Licensing',
      bodyClass: 'page-form page-directLicensing',
      jsFile: 'formPage'
    },
    '/account/edit' : {
      title: 'Edit Account',
      bodyClass: 'page-form page-editAccount',
      jsFile: 'formPage'
    },
    '/track/edit/' : {
      title: 'Edit Track',
      bodyClass: 'page-form page-editTrack',
      jsFile: 'formPage'
    },
    '/album/edit/' : {
      title: 'Edit Album',
      bodyClass: 'page-form page-editAlbum',
      jsFile: 'formPage'
    },
    '/playlist/edit/' : {
      title: 'Edit Playlist',
      bodyClass: 'page-form page-editPlaylist',
      jsFile: 'formPage'
    },
    '/opportunities/editResponse/' : {
      title: 'Edit Response',
      bodyClass: 'page-form page-editPlaylist',
      jsFile: 'formPage'
    },
    '/collection/editPublic/' : {
      title: 'Edit Public Collection',
      bodyClass: 'page-form page-editPublicCollection',
      jsFile: 'formPage'
    },
    '/collection/editPrivate/' : {
      title: 'Edit Private Collection',
      bodyClass: 'page-form page-editPrivateCollection',
      jsFile: 'formPage'
    },
    '/account/user/' : {
      title: 'Edit User',
      bodyClass: 'page-form page-editProfile',
      jsFile: 'formPage'
    },
    '/request/editProject' : {
      title: 'Edit Project Request',
      bodyClass: 'page-form page-editRequest',
      jsFile: 'formPage'
    },
    '/request/editArtist' : {
      title: 'Edit Artist Request',
      bodyClass: 'page-form page-editRequest',
      jsFile: 'formPage'
    },
    '/request/editInbox' : {
      title: 'Edit Inbox Request',
      bodyClass: 'page-form page-editRequest',
      jsFile: 'formPage'
    },
    '/account/my-orders/view' : {
      title: 'View Invoice',
      bodyClass: 'page-form page-view-invoice',
      jsFile: 'formPage'
    },
    '/account/my-sales/view' : {
      title: 'View Invoice',
      bodyClass: 'page-form page-view-invoice',
      jsFile: 'formPage'
    },
    '/opportunities' : {
      title: 'Opportunities',
      bodyClass: 'page-requests',
      jsFile: 'outside',
      sidebar: false
    },
    '/playlists' : {
      title: 'Playlist',
      bodyClass: 'page-playlist',
      jsFile: 'outside',
      sidebar: false
    },
    '/albums' : {
      title: 'Albums',
      bodyClass: 'page-catalog-albums',
      jsFile: 'outside',
      sidebar: false
    },
    '/' : {
      jsFile: 'home',
      title: 'Home',
      bodyClass: 'page-home',
      sidebar: false
    }
  },
  init: function() {
    // load the controllers
    
    window.jsFiles = {
      addTrackModal: require('../controllers/add-track-modal'),
      updateTrackModal: require('../controllers/update-track-modal'),
      addVideoModal: require('../controllers/add-video-modal'),
      uploadSpreadsheetModal: require('../controllers/upload-spreadsheet-modal'),
      // updateVideoModal: require('../controllers/update-video-modal'),
      sharePlaylistModal: require('../controllers/share-playlist-modal'),
      // addWriterModal: require('../controllers/add-writer-modal'),
      manageAudioPage: require('../controllers/manage-audio'),
      calendarPage: require('../controllers/calendar'),
      // addPublisherModal: require('../controllers/add-publisher-modal'),
      addPartnerModal: require('../controllers/add-partner-modal'),
      addCatalogModal: require('../controllers/add-catalog-modal'),
      // addFolderModal: require('../controllers/add-folder-modal'),
      // updateFolderModal: require('../controllers/update-folder-modal'),
      listPage: require('../controllers/list-page'),
      // signupWizard: require('./signup-wizard'),
      // forms: require('../controllers/forms'),
      formPage: require('../controllers/form-page'),
      tracks: require('../controllers/tracks'),
      home: require('../controllers/home'),
      uploadImageModal: require("../controllers/upload-image-modal"),
      uploadFileModal: require("../controllers/upload-file-modal"),
      player: require('../controllers/player'),
      account: require('../controllers/account'),
      soundMap: require('../controllers/sound-map'),
      outside: require('../controllers/outside'),
      trackMessage: require('../controllers/track-message-modal'),
    }
    
    // init the dropzone function
    window.initDropzone = function(selector, additionalParams, successCallback, mimeTypes, singleFlag, singleStartTrigger) {
      
      let $dropzone = new Dropzone(selector, { 
          url: function(files) {
            return files[0].uploadServer + '/uploadChunks'; 
          },
          parallelUploads: 10,
          acceptedFiles: mimeTypes,
          dictDefaultMessage: 'Drag tracks here to upload',
          paramName: 'tracks',
          // uploadMultiple: true
          addRemoveLinks: true,
          params: function (files, xhr, chunk) {
            return {
              unique_id: chunk.file.upload.uuid,
              chunk_index: chunk.index
            }
          },
          maxFiles: singleFlag ? 1 : null,
          timeout: 600000,
          chunking: true,
          chunkSize: 5000000,
          parallelChunkUploads: 6,
          retryChunks: true,
          forceChunking: true,
          accept: function(file, done) {
            // options for the managed upload for this accepted file
            // define the bucket, and the S3 key the file will be stored as
            console.log(file);
            $.ajax($('body').data('uploadhost') + '/getUploadKey', {
              method: 'post',
              data: {
                filename: file.name,
                mime_type: file.type,
                file_size: file.size,
                total_chunks: file.upload.totalChunkCount,
                unique_id: file.upload.uuid,
                subscriber_id: $('#subscriber-name').data('id')
              },
              success: function(xhr) {
                if(xhr.error) {
                  toastr.error(xhr.error);
                } else {
                  file.uploadServer = xhr.uploadServer;
                  file.baseKey = xhr.base_key;
                  done();
                  // file.s3upload = new AWS.S3.ManagedUpload({params: xhr});
                  // done();      
                }
              }
            })
          },
          chunksUploaded: function (file, done) {
            // All chunks have been uploaded. Perform any other actions
            console.log('Chunks complete ' + file.name + ' ('+file.upload.uuid+') ' + new Date());
            console.log('in mergechunks', file);
            //return;
            done();
            let data = {
              unique_id: file.upload.uuid,
              total_chunks: file.upload.totalChunkCount,
            }
            if($('#add-to-catalog-check').length > 0 && $('#add-to-catalog-check').is(':checked')){
              data.add_to_catalog = 'Y';
            }
            for(let key in additionalParams) {
              data[key] = additionalParams[key];
            }
            console.log('Starting merge chunks....');
            $.ajax({
                type: "post",
                data: data,
                url: file.uploadServer + '/mergeChunks',
                timeout: 300000,
                success: function (xhr) {
                  // Must call done() if successful
                  console.log('File moved to final location ' + new Date());
                  $.ajax({
                    type: "post",
                    data: data,
                    url: '/track/finalizeUpload',
                    success: function(xhr) {
                      successCallback($dropzone, file, xhr);        
                    }
                  })
              
                },
                error: function (msg) {
                    console.log(msg);
                    // $dropZone[0].dropzone._errorProcessing([currentFiles[currentUUID]], msg.responseText);
                }
             });
          },
          init: function() {
            var myDropzone = this;
            this.on('addedfile', function(file, xhr, formData) {
              console.log('File added ' + file.name + ' ('+file.upload.uuid+') '+ new Date());
              window.pageChangeWarning = function() {
                if(confirm("Leaving this page will cancel uploads. Are you sure?")) {
                  myDropzone.removeAllFiles(true);
                  delete window.pageChangeWarning;
                  return true;
                } else {
                  return false;
                }
              }
              // console.log('addedfile', file, myDropzone);
            });
            this.on('queuecomplete', function() {
              delete window.pageChangeWarning;
            });
            this.on('canceled', function(file) {
              console.log('in cancel', file);
              $.ajax({
                type: "post",
                data: { unique_id: file.upload.uuid },
                url: file.uploadServer + '/cancelSession',
                timeout: 300000,
                success: function (data) {
                    // Must call done() if successful
                    console.log('Upload cancelled ' + new Date());
                    
                },
                error: function (msg) {
                    console.log(msg);
                    // $dropZone[0].dropzone._errorProcessing([currentFiles[currentUUID]], msg.responseText);
                }
             });
            });
            this.on('error', function(xhr, errorMessage) {
              myDropzone.removeFile(xhr);
              if(errorMessage != 'Upload canceled.') {
                toastr.error(errorMessage);
              }
            });
          }
        });
    }
    
    // {{#compare val1 val2 operator="=="}}{{/compare}}
    Handlebars.registerHelper('compare', function(lvalue, rvalue, options) {

    	if (arguments.length < 3)
    	    throw new Error("Handlerbars Helper 'compare' needs 2 parameters");
    
    	operator = options.hash.operator || "==";
    
    	var operators = {
    	    '==':       function(l,r) { return l == r; },
    	    '===':      function(l,r) { return l === r; },
    	    '!=':       function(l,r) { return l != r; },
    	    '<':        function(l,r) { return l < r; },
    	    '>':        function(l,r) { return l > r; },
    	    '<=':       function(l,r) { return l <= r; },
    	    '>=':       function(l,r) { return l >= r; },
    	    'typeof':   function(l,r) { return typeof l == r; }
    	}
    
    	if (!operators[operator])
    	    throw new Error("Handlerbars Helper 'compare' doesn't know the operator "+operator);
    
    	var result = operators[operator](lvalue,rvalue);
    
    	if( result ) {
    	    return options.fn(this);
    	} else {
    	    return options.inverse(this);
    	}
    });
    
    Handlebars.registerHelper('ifIn', function(elem, list, options) {
      if(Array.isArray(list) && list.indexOf(elem) > -1) {
        return options.fn(this);
      } else if((!Array.isArray(list) || list.length == 0) && elem == '') {
        return options.fn(this);
      }
      return options.inverse(this);
    });
    
    Dropzone.autoDiscover = false;

    toastr.options = {
      "closeButton": false,
      "debug": false,
      "newestOnTop": false,
      "progressBar": false,
      "positionClass": "toast-top-center",
      "preventDuplicates": true,
      "onclick": null,
      "showDuration": "300",
      "hideDuration": "1000",
      "timeOut": "2000",
      "extendedTimeOut": "1000",
      "showEasing": "swing",
      "hideEasing": "linear",
      "showMethod": "fadeIn",
      "hideMethod": "fadeOut"
    }
  
    window.config = {};
    window.config.tinymce = { 
      selector:'textarea.wysiwyg-editor',
      menubar: false,
      plugins: [
        // 'advlist autolink lists link image charmap print preview anchor code',
        'advlist autolink lists link charmap print preview anchor code',
        'searchreplace visualblocks code fullscreen',
        //'insertdatetime media table contextmenu paste code help wordcount'
        'insertdatetime table contextmenu paste code help wordcount'
      ],
      toolbar: 'insert | undo redo | bold italic | bullist numlist outdent indent | removeformat | code',
      content_css: [
        '//fonts.googleapis.com/css?family=Lato:300,300i,400,400i',
        // '/css/main.css'
        ]
      , 
      height: 300,
      branding: false
      /*
      setup: function(ed) { 
        tinymceInit(ed);
      }
      */
    };
  
    // the container for the player
    if(!window.localStorage.player) {
      window.localStorage.player = JSON.stringify({
        playlist: [],
        album_id: false,
        playlist_id: false,
        currentTrack: 0,
        volume: 1,
        mute: false,
        playing: false
      });
    }
  
    for(var key in window.jsFiles) {
      // console.log(jsFiles[key]);
      window.jsFiles[key].init();
    }
    
    // make enter submit the forms in modals
    $('.modal').on('keypress', 'input, select', function(e) {
      if(e.which == 13) {
        e.preventDefault();
        $(this).closest('.modal').find('.btn-primary').trigger('click');
      }
      
    });
    
    // add play video
    $('body').on('click', '.play-video', function(e) {
      e.preventDefault();
      e.stopPropagation();
      // console.log('watching video');
      if($('#pause-control').is(":visible")) {
        $('#pause-control').trigger('click');
      }
      $('#watchVideoLabel').html($(this).data('name') + ' (Preview)');
      $('#watchVideoModal video').attr('src', '/video/watch/' + $(this).data('videoid'));
      $('#watchVideoModal').modal('show');
      setTimeout(function() {
        $('#watchVideoModal video')[0].play();  
      }, 1000);
    });
    
    $('#watchVideoModal').on('hide.bs.modal', function(e) {    
      var vid = document.getElementById("modal-video"); 
      vid.pause(); 
    });
    
    $('body').on('click','a, button', function (e) {
      if(!$(this).hasClass('reload-link') && !($(this).attr('target') == 'blank') && !($(this).attr('target') == '_blank')) {
        var href = $(this).attr('href');
        if($(this).hasClass('alert-link')) {
          $('.modal').modal('hide');
        }
        if(href && href.substring(0, 1) != '#' && href.substring(0, 4) != 'http') {
          e.preventDefault();
          e.stopPropagation();
          router.goToUrl(href);
        } else if(href && href.substring(0, 4) == 'http') {
          document.location = href;
        }
      } else if($(this).hasClass('reload-link')) {
        document.location = $(this).attr('href');
      }
    });
    
    $('body').on('click', '.download-track', function(e) {
      e.preventDefault();
      document.location = $('body').data('ajaxbaseurl') + "/track/download/" + $(this).data('track') + ($(this).data('format') ? '?format=' + $(this).data('format') : ''); 
    });
    $('body').on('click', '.download-video', function(e) {
      e.preventDefault();
      document.location = $('body').data('ajaxbaseurl') + "/track/downloadVideo/" + $(this).data('video'); 
    });
    
    // subscriber to track transcoding updates
    window.socketIO = io.connect(window.location.protocol + '//' + window.location.host, {
       "transports": ['websocket']
    });
    window.socketIO.on('/trackUpdates/' + $('#subscriber-name').data('id'), function (data) {
      // console.log(data);
      if(data.status == 'transcoding-complete') {
        setTimeout(function() {
          $('i.play-track[data-track="' + data.id +'"]').removeClass('disabled');
          $('i.download-track[data-track="' + data.id +'"]').removeClass('disabled');  
        }, 5000);
        
      } else {
        // toastr.error('The track "' + data.name + '" could not be transcoded. Please try another file.');
      }
    });
    
    window.socketIO.on('/videoUpdates/' + $('#subscriber-name').data('id'), function (data) {
      // console.log(data);
      if(data.status == 'transcoding-complete') {
        setTimeout(function() {
          $('i.play-video[data-videoid="' + data.id +'"]').removeClass('disabled');
          $('i.download-video[data-videoid="' + data.id +'"]').removeClass('disabled');
        }, 5000);
      } else {
        // toastr.error('The video "' + data.name + '" could not be transcoded. Please try another file.');
      }
    });
    
    // view url
    $('body').on('click', '.view-url', function(e) {
      e.preventDefault();
      window.open($(this).data('url'), '_blank');
    })
    $('body').on('click', '.button-link', function(e) {
      e.preventDefault();
      router.goToUrl($(this).data('url'), true);
    });
    
    // datatables paginate button
    $('body').on('click', '.dataTables_paginate .paginate_button', function(e) {
      $('html, body').animate({
        scrollTop: 0
        }, 250);
    })
    
    router.showCartButton();
    
    // look for a track deeplink
    if(($('body.page-catalog').length > 0 || $('body.page-playlist').length > 0) && document.location.hash && document.location.hash.length > 7 && document.location.hash.substring(0, 7) == '#track-') {
      router.showTrackDetailsModal(document.location.hash.substring(7), {subdomain: $('div.content').data('subdomain')});
    }
    
    // launch track details modal
    $('body').on('click', '.track-details', function(e) {
      e.preventDefault();
      let data = {};
      if($('body.page-playlist').length > 0) {
        data.playlist_id = $('div.content').data('playlist');
      }
      if($('body.page-catalog').length > 0) {
        data.subdomain = $('div.content').data('subdomain');
      }
      router.showTrackDetailsModal($(this).data('track'), data);
    })
    $('body').on('click', '.copy-track', function(e) {
      e.preventDefault();
      var textarea = document.createElement('textarea');
      textarea.textContent = window.location.href;
      document.body.appendChild(textarea);
    
      var selection = document.getSelection();
      var range = document.createRange();
    //  range.selectNodeContents(textarea);
      range.selectNode(textarea);
      selection.removeAllRanges();
      selection.addRange(range);
    
      console.log('copy success', document.execCommand('copy'));
      selection.removeAllRanges();
    
      document.body.removeChild(textarea);
      toastr.success('URL copied.');
    });
    
    $('#trackDetailModal').on('hidden.bs.modal', function () {
      if(document.location.href.indexOf('#') != -1) {
        $('title').html($('body').data('oldtitle'));
        history.pushState({}, $('body').data('oldtitle'), document.location.href.substring(0, document.location.href.indexOf('#')));  
      }
    });
      
    // add some global functions to the window object
    window.goToUrl = router.goToUrl;
    window.initElementor = function() {
      let scripts = [
        /*
        '/wpi/js/jquery/ui/core.min.js',
        '/wpi/js/jquery/ui/widget.min.js',
        '/wpi/js/jquery/ui/mouse.min.js',
        '/wpi/js/jquery/ui/draggable.min.js',
        '/wpi/js/underscore.min.js',
        '/wpi/js/backbone.min.js',
        '/elementor/lib/backbone/backbone.marionette.min.js',
        '/elementor/lib/backbone/backbone.radio.min.js',
        '/elementor/lib/dialog/dialog.min.js',
        */
        "/elementor/js/common.min.js",
        '/elementor/js/frontend.min.js'
      ]
      for(let i = 0; i < scripts.length; i++) {
        $.getScript(scripts[i]);
      }
    }
    window.openModal = function(modalName) {
      $('.modal').modal('hide');
      $(modalName).modal('show');
    }
    
     // sidebar
    $('body').on('click', '.navbar-toggler, .menu-overlay', function (e) {
        e.preventDefault();
        $('#sidebar').toggleClass('active');
        $('body').toggleClass('nav-open');
    });
    
    // check if the page is scrolling
    if($('header .navbar').length > 0){
      $(window).on("scroll load resize", function(){
        router.checkScroll();
      });
      router.checkScroll();
    }
    
    $(window).bind('popstate', function(event){
      // console.log(event);
      var url = document.location.pathname +  (document.location.search ?  document.location.search : '') +  (document.location.hash ? '#' + document.location.hash.substring(1) : '');
      let go = true;
      if(document.location.hash.length >= 2) {
        if(document.location.hash.substring(0, 2) == '#!') {
          go = false;
        }
      }
      if(go) {
        // Update the contents of the page with whatever url was stored in the state.
        router.goToUrl(url, false);
      }
    });
    
  },
  showTrackDetailsModal: function(track_id, data) {
    $.ajax('/track/details/' +track_id, {
      method: 'GET',
      data: data,
      success: function(xhr) {
        if(xhr.track) {
          var source = $("#track-detail").html();
          var template = Handlebars.compile(source);
          // console.log(xhr);
          var html = template(xhr);
          $('#trackDetailLabel').html(xhr.track.name);
          $('#trackDetailModal .modal-body').html(html);
          if(xhr.track.download_access) {
            let buttons = '';
            buttons += '<button class="btn btn-secondary copy-track" data-track="' + xhr.track.track_id + '">Copy URL</button>';
            if(xhr.track.mp3_available) {
              buttons += '<button class="btn btn-secondary download-track" data-track="' + xhr.track.track_id + '"><i class="icon download-track"></i>MP3</button>';
            }
            if(xhr.track.wav_available) {
              buttons += '<button class="btn btn-secondary download-track" data-track="' + xhr.track.track_id + '" data-format="wav"><i class="icon download-track"></i>WAV</button>';
            }
            if(xhr.track.aiff_available) {
              buttons += '<button class="btn btn-secondary download-track" data-track="' + xhr.track.track_id + '" data-format="aiff"><i class="icon download-track"></i>AIFF</button>';
            }
            $('#trackDetailDownloadButtons').html(buttons);
          } else{
            $('#trackDetailDownloadButtons').html('');
          }
          if(document.location.hash.indexOf(xhr.track.track_id) == -1) {
            $('body').data('oldtitle', $('title').html());
            let newUrl = document.location.href;
            if(newUrl.indexOf('#') > -1) {
              newUrl = newUrl.substring(0, newUrl.indexOf('#'));
            }
            $('title').html(xhr.track.name + ' : Otis Labs');
            history.pushState({}, xhr.track.name + ' : Otis Labs', newUrl + '#track-' + xhr.track.track_id);  
          } else {
            $('title').html(xhr.track.name + ' : Otis Labs');
          }
          $('#trackDetailModal').modal('show');
        } 
      }
    });
  }, 
  // https://otis-app-2-restlessmike.c9users.io:8081/track/edit/5a2716318484005abf72600bb59536f914e0cd7a90a501086adfdd99f18db6f6
  goToUrl: function(url, updatePushstate) {
    // $('#content > .vbox').fadeOut(200);
    let processResponse = function(data, status, xhr,  callback) {
      var testURL = url;
      if(testURL.indexOf('#') != -1) {
        testURL = testURL.substr(0, testURL.indexOf('#'));
      }
      if(xhr.responseURL.substr(xhr.responseURL.indexOf('/', 8)) != testURL) {
        document.location = xhr.responseURL;
      } else {
        var page = false;
        if(testURL == '/' && document.location.hostname.endsWith('.otis.is')) {
          page = router.pages['/c/'];
        } else {
          for(var key in router.pages) {
            if(url.substring(0, key.length) == key) {
              page = router.pages[key];
              break;
            }
          }
        }
        if(!page) {
          
        }
        // console.log(page);
        
        var title = $('<div>' + data + '</div>').find('title').html();
        $('title').html(title);
        
        var pointer = data.indexOf('<body') + 5;
        pointer = data.indexOf('class="', pointer) + 7;
        var bodyClasses = data.substring(pointer, data.indexOf('"', pointer)); 
        $('body').attr('class', '');
        var bodyClassesArr = bodyClasses.split(' ');
        document.body.classList.add(...bodyClassesArr);
        if($('body').hasClass('page-generic')) {
          /*
          $('script.generic-js').each(function(index, elt) {
            try {
              $.getScript($(elt).attr('src'));
            } catch(e) {
              console.log('error loading elementor', e);
            }
          })
          */
          window.initElementor();
        }
        // if($('body').hasClass('no-sidebar')) {
          $('#sidebar').removeClass('active');
        // }
        
        
        var bodyContent = $('<div>' + data + '</div>').find('#content');
        $('body').find('#content').html(bodyContent.html());
        
        var navContent = $('<div>' + data + '</div>').find('#sidebar');
        
        // get the current cart buttons and replace the new ones with it
        let cartButton = $('body li.snipcart-checkout-container-1').html();
        $(navContent).find('li.snipcart-checkout-container-1').html(cartButton);
        
        $('body').find('#sidebar').html(navContent.html());
        
        if(page) {
          // update body class
          /*
          $("body").removeClass (function (index, className) {
              return (className.match (/\bpage-\S+/g) || []).join(' ');
          });
          */
          /*
          $('body').removeClass('no-sidebar');
          if(page.hasOwnProperty('sidebar') && page.sidebar === false) {
            $('body').addClass('no-sidebar');
          }
          if(page.hasOwnProperty('bodyClass')) {
            if(Array.isArray(page.bodyClass)) {
              for(var i = 0; i < page.bodyClass.length; i++) {
                $('body').addClass(page.bodyClass[i]);  
              }
            } else {
              $('body').addClass(page.bodyClass);  
            }
          }
          */
          router.showCartButton();
          
          // update the title, add to history
          if(updatePushstate !== false) {
            history.pushState({}, page.title + ' : Otis Labs', url);   
          }
          
          // run page JS
          if(page.hasOwnProperty('jsFile')) {
            if(Array.isArray(page.jsFile)) {
              for(var i = 0; i < page.jsFile.length; i++) {
                router.runPageJS(page.jsFile[i]);
              }
            } else {
              router.runPageJS(page.jsFile);  
            }
          }
          // for forms
          if($('body').hasClass('page-form') && window.location.hash) {
            $("button[data-tab='"+window.location.hash.substring(1)+"']").trigger('click'); 
          }
          // if track deep link show
          if($('body.page-catalog').length > 0 && document.location.hash && document.location.hash.length > 7 && document.location.hash.substring(0, 7) == '#track-') {
            router.showTrackDetailsModal(document.location.hash.substring(7), {subdomain: $('div.content').data('subdomain')});
          }
        }
        $("html, body").animate({ scrollTop: 0 }, 250);
      }
    }
    let loadNewPage = function() {
      $('.modal').modal('hide');
      $('i').popover('hide');
      var xhr;
      var _orgAjax = $.ajaxSettings.xhr;
      $.ajaxSettings.xhr = function () {
        xhr = _orgAjax();
        return xhr;
      };
      // console.log('gotourl', url);
      if(url.indexOf('state=') > -1 && url.indexOf('#') > -1) {
        var el = document.createElement('a');
        el.href = url;
        
        url =  el.pathname + el.hash;
        $.ajax(url, {
          type: 'GET',
          success: function(data, status) {
            processResponse(data, status, xhr);
          }
        });
      } else {
        $.ajax(url, {
          type: 'GET',
          success: function(data, status) {
            processResponse(data, status, xhr);
          }
        });
      }
    }
    if(window.hasOwnProperty('pageChangeWarning')) {
      if(window.pageChangeWarning()) {
        loadNewPage();
      }  
    } else {
      loadNewPage();
    }
  },
  runPageJS: function(pageKey) {
    window.jsFiles[pageKey].onload();
  },
  showCartButton: function() {
    // show the show cart button if it isn't empty
    setTimeout(function() {
      var temp = $('.snipcart-total-items').html();
      if(temp && temp != '0') {
        $('.snipcart-checkout').show();
      }
    }, 500);  
  },
  checkScroll: function(){
    var startY = $('header .navbar').height() * 2; //The point where the navbar changes in px
  
    if($(window).scrollTop() > startY){
      $('header').addClass("scrolled");
    } else {
      $('header').removeClass("scrolled");
    }
  }
}
module.exports = router;