// $(document).ready(function(){
module.exports = {
  init: function() {
    $('body').on('click', '.delete-user', function(e) {
      e.preventDefault();
      var $this = $(this);
      if(confirm('Are you sure you want to remove ' + $this.data('name') + '?')) {
        $.ajax('/account/user/delete/' + $this.data('user'), {
          method: 'post',
          success: function(xhr) {
            $this.closest('tr').remove();
          }
        });
      }
    });
    
    $('body').on('click', '.add-user', function(e) {
      $('#inviteUserModal').modal('show');
    });
    
    // invite user
    $('body').on('click', '#inviteUserModal-invite', function(e) {
      e.preventDefault();
      var data = {
        email: $('#inviteUserModal-email').val(),
        first_name: $('#inviteUserModal-firstName').val(),
        last_name: $('#inviteUserModal-lastName').val(),
      }
      var roles = [];
      $(".inviteUserModal-roles input[type='checkbox']").each(function(index) { 
        if($(this).is(':checked')) {
          roles.push($(this).val());
        }
      });
      data.roles = roles.join(',');
      $.ajax('/account/user/add', {
        method: 'post',
        data: data,
        success: function(xhr) {
          if(xhr.hasOwnProperty('success')) {
            $('#inviteUserModal-email').val('');
            $('#inviteUserModal-firstName').val('');
            $('#inviteUserModal-lastName').val('');
            $(".inviteUserModal-roles input[type='checkbox']").prop('checked', false);
            $('#inviteUserModal-error').html('').hide();
            $('.update-filter').trigger('click');
            $('#inviteUserModal').modal('hide'); 
          } else {
            var errorText = '<ul>';
            for(var key in xhr.errors) { 
              errorText += '<li>' + xhr.errors[key] + '</li>';
            }
            $('#inviteUserModal-error').html(errorText + '<ul>').show();
          }
        }
      })
    });
    
    $('body').on('click', '#switchAccountModal-submit', function(e) {
      e.preventDefault();
      document.location = '/account/switch/' + $('#switchAccountModal-accountID').val();
    });
    
    $('body').on('click', '#signupModal-signup', function(e) {
      e.preventDefault();
      var data = {};
      for(var i = 0; i < $('#signup-form input').length; i++) {
        data[$($('#signup-form input')[i]).attr('name')] = $($('#signup-form input')[i]).val();
      }
      console.log(data);
      $.ajax('/signup', {
        method: 'POST',
        data: data,
        success: function(xhr) {
          console.log(xhr);
          if(xhr.success) {
            $('#signupModal-error').hide();
            if(xhr.redirect) {
              document.location = xhr.redirect;
            } else {
              $('#loginModal-success').html('Your account has been created! Please log in below.');
              $('#loginModal-success').show();
              window.openModal('#loginModal');
            }
          } else {
            var html = '';
            for(var key in xhr.error) {
              html += '<li>' + xhr.error[key] + '</li>';
            }
            $('#signupModal-error').html('<ul>'+html+'</ul>');
            $('#signupModal-error').show();
          }
        }
      });
    });
  }
};

