var updateTrackModal = {
  trackID: false,
  objectID: false,
  objectType: false,
  folderID: false,
  openModal: function(trackID) {
    updateTrackModal.trackID = trackID;
    jQuery.ajax('/track/updateModal/' + updateTrackModal.trackID, {
      type: 'GET',
      success: function(data) {
        $('.modal').modal('hide');
        $('#updateTrackModal-trackName').val(data.track_name);
        // $('#updateTrackModal-catalogNumber').val(data.catalog_num);
        $('#updateTrackModal-artistName').append('<option value="'+data.artist_name + '" selected>' +data.artist_name + '</option>');
        $('#updateTrackModal-trackFilename').val(data.track_filename);
        $('#updateTrackModal-catalogs').html('');
        for(let i = 0; i < data.catalogs.length; i++) {
          var newOption = new Option(data.catalogs[i].text, data.catalogs[i].value, true, true);
          $('#updateTrackModal-catalogs').append(newOption);
        }
        $('#updateTrackModal-catalogs').trigger('change')
        
        // add playlists 
        let deleteIndex= false;
        $('#updateTrackModal-jumpToPlaylist').html('<option value="">Choose a playlist below to see other tracks</option>');
        for(let i = 0; i < data.playlists.length; i++) {
          if($('.button-bar').data('objectid') != data.playlists[i].key) {
            $('#updateTrackModal-jumpToPlaylist').append('<option value="' + data.playlists[i].key + '">' + data.playlists[i].value + '</option>' );
          } else {
            deleteIndex = i; 
          }
        }
        if(deleteIndex !== false) {
          data.playlists.splice(deleteIndex, 1);
        }
        
        // add albums
        $('#updateTrackModal-jumpToAlbum').html('<option value="">Choose an album below to see other tracks</option>');
        for(let i = 0; i < data.albums.length; i++) {
          if($('.button-bar').data('objectid') != data.albums[i].key) {
            $('#updateTrackModal-jumpToAlbum').append('<option value="' + data.albums[i].key + '">' + data.albums[i].value + '</option>' );
        ``} else {
            deleteIndex = i;
          }
        }
        if(deleteIndex !== false) {
          data.albums.splice(deleteIndex, 1);
        }
        
        console.log(data);
        
        if(document.location.href.indexOf('manage-audio') !== false) {
          $('#updateTrackModal .trackfilename-container').css('display', 'block');  
        } else {
          $('#updateTrackModal .trackfilename-container').css('display', 'none');
        }
        if($('.track-chooser-filter-container').length > 0) {
          $('.more-by-artist').css('display', 'inline');
          if(data.playlists.length > 0) {
            $('.jumptoplaylist-container').css('display', 'block');
          } else {
            $('.jumptoplaylist-container').css('display', 'none');
          }
          if(data.albums.length > 0) {
            $('.jumptoalbum-container').css('display', 'block');
          } else {
            $('.jumptoalbum-container').css('display', 'none');
          }
        } else {
          $('.more-by-artist').css('display', 'none');
          $('.jumptoplaylist-container').css('display', 'none');
          $('.jumptoalbum-container').css('display', 'none');
        }
        
        $('#updateTrackModal').modal('show');
      }
    });
  },
  init: function() {
    $('#updateTrackModal-update, #updateTrackModal-details').on('click', function(e) {
    var which = $(this).attr('id');
    e.preventDefault();
    var validator = $('#updateTrackModal-form').validate({
      highlight: function (element, errorClass, validClass) {
        var elem = $(element);
        if (elem.hasClass("select2-hidden-accessible")) {
            elem.parent().find('.select2-selection').addClass(errorClass);
            // $("#s2id_" + elem.attr("id") + " ul").addClass(errorClass);
        } else {
            elem.addClass(errorClass);
        }
      },
  
      unhighlight: function (element, errorClass, validClass) {
        var elem = $(element);
        if (elem.hasClass("select2-hidden-accessible")) {
            elem.parent().find('.select2-selection').removeClass(errorClass);
            // $("#s2id_" + elem.attr("id") + " ul").removeClass(errorClass);
        } else {
            elem.removeClass(errorClass);
        }
      }
    });
    if(validator.form()) {
      var data = {};
      data['track_name'] = $('#updateTrackModal-trackName').val();
      // data['catalog_num'] = $('#updateTrackModal-catalogNumber').val();
      data['artist_name'] = $('#updateTrackModal-artistName').val();
      if($('.trackfilename-container').is(':visible')) {
        data['track_filename'] = $('#updateTrackModal-trackFilename').val();
      }
      data['catalogs'] = $('#updateTrackModal-catalogs').val();
      if(updateTrackModal.objectID) {
        data[updateTrackModal.objectType + '_id'] = updateTrackModal.objectID;
        if(updateTrackModal.folderID) {
          data['folder_id'] = updateTrackModal.folderID;
        }
      }
      console.log(data);
      jQuery.ajax({
        url: '/track/updateModal/' + updateTrackModal.trackID,
        data: data,
        type: 'POST',
        success: function(data){
          if(!data.error) {
            // for the listing pages
            if($('.update-filter').length > 0) {
              $('.update-filter').trigger('click');
            }
            // for the album and playlist forms
            if($('.button-bar').length > 0 && $('.button-bar').data('objecttype')) {
              $('li[data-objectid='+updateTrackModal.trackID+'] a.edit-track').text($('#updateTrackModal-trackName').val());
              $('li[data-objectid='+updateTrackModal.trackID+'] span.sublabel').text($('#updateTrackModal-artistName').val());
              if($('.album-tracks li[data-objectid='+updateTrackModal.trackID+']').length == 0) {
                /*            
                // update the listing
                $('.track-chooser-filter').trigger('change');
                */
                var url = '', target = '';
                if($('.button-bar').data('objecttype') == 'album') {
                  url = '/album/listTracks/' + $('.button-bar').data('objectid');
                  target = '.album-tracks';
                } else if($('.button-bar').data('objecttype') == 'playlist') {
                  url = '/playlist/listTracks/' + $('.button-bar').data('objectid');
                  target = '.album-tracks';
                } else if($('.button-bar').data('objecttype') == 'request') {
                  url = '/opportunities/' + $('.button-bar').data('objectid') + '/listTracks';
                  target = '.album-tracks';
                }
                
                window.trackChooser.lastModifiedDate = new Date();
                $.ajax(url, {
                  complete: function(xhr) {
                    //console.log(xhr);
                    window.trackChooser.processTrackList(xhr, false);
                    /*        
                    var source = $("#track-listing-template").html();
                    var template = Handlebars.compile(source);
                    var html = template({tracks: xhr.responseJSON});
                    // console.log(html, xhr.responseJSON);
                    window.trackChooser.lastModifiedDate = new Date();
                    $(target).html(html);
                    */
                  }
                });
              }
              updateTrackModal.objectID = false;
              updateTrackModal.objectType = false;
              updateTrackModal.folderID = false;
              
            }// for the response page
            $('#updateTrackModal .error-container').html('');
            $('.modal .alert').css('display', 'none');
            $('#updateTrackModal-form')[0].reset(); 
            $("#updateTrackModal-artistName").select2('val', 'All');
            
            if($('.response-container').length > 0) {
              window.jsFiles.outside.updateTrackList();
            }
            if(which == 'updateTrackModal-details') {
              $('#updateTrackModal').modal('hide');
              window.goToUrl('/track/' + updateTrackModal.trackID);
            } else {
              $('#updateTrackModal').modal('hide');
              if(document.location.href.indexOf('manage-audio') !== false) {
                $('.responsive-data-table').DataTable().draw();
              }
            }
          } else {
            $('#updateTrackModal .error-container').html('<div class="alert alert-danger alert-dismissible"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button>' + data.error + '</div>');
            $('.modal .alert').css('display', 'block');
          }
        }
      });
    }
  });
  
  // quick add form
  $.fn.modal.Constructor.prototype.enforceFocus = function() {};
  $("#updateTrackModal-artistName").select2({
    tags: true,
    allowClear: true,
    placeholder: '',
    ajax: {
      url: "/artist/list",
      dataType: 'json',
      delay: 250,
      data: function (params) {
        return {
          q: params.term, // search term
          page: params.page
        };
      },
      processResults: function (data, params) {
        // parse the results into the format expected by Select2
        // since we are using custom formatting functions we do not need to
        // alter the remote JSON data, except to indicate that infinite
        // scrolling can be used
        params.page = params.page || 1;
        
  
        return {
          results: data,
          pagination: {
            more: (params.page * 20) < data.total_count
          }
        };
      },
      cache: true
    },
    escapeMarkup: function (markup) { return markup; }, // let our custom formatter work
  });
  $("#updateTrackModal-catalogs").select2({
    tags: false,
    allowClear: true,
    ajax: {
      url: "/catalogs/get",
      dataType: 'json',
      delay: 250,
      data: function (params) {
        return {
          q: params.term, // search term
          page: params.page
        };
      },
      processResults: function (data, params) {
        // parse the results into the format expected by Select2
        // since we are using custom formatting functions we do not need to
        // alter the remote JSON data, except to indicate that infinite
        // scrolling can be used
        params.page = params.page || 1;
        
  
        return {
          results: data,
          pagination: {
            more: (params.page * 20) < data.total_count
          }
        };
      },
      cache: true
    },
    escapeMarkup: function (markup) { return markup; }, // let our custom formatter work
  });
  // to make validation errors shouw up correctly
  setTimeout(function() {
    $('#updateTrackModal #select2-updateTrackModal-artistName-container').closest('.select2').insertBefore($('#updateTrackModal-artistName'));
  }, 200);
  }
}
module.exports = updateTrackModal;