// $(document).ready(function(){
var soundMap = {
  positions: {
    mood: {
      red: false,
      blue: false,
      yellow: false
    },
    genre: {
      red: false,
      blue: false,
      yellow: false
    }
  },
  statuses: {
    mood: {
      red: false,
      blue: false,
      yellow: false
    },
    genre: {
      red: false,
      blue: false,
      yellow: false
    }
  },
  populateCharts: function() {
    var objectType = $('.pie-container').data('type');
    var objectID = $('.pie-container').data('id');
    if(objectType && objectID) {
      $.ajax('/' + objectType + '/getPins/' + objectID, {
        method: 'get',
        success: function(xhr) {
          
          for(var i = 0; i < xhr.length; i++) {
            var $pieImage = $('.' + xhr[i].c + '-pie');
            var $pieContainer = $('.pie-container.' + xhr[i].c);
            var $tab = $pieImage.closest('.form-tab');
            var rehide = false;
            if($tab.css('display') == 'none') {
              rehide = true;
              $tab.css('visibility', 'hidden');
              $tab.css('display', 'block');
            }
            var width = $pieImage.outerWidth(), height = $pieImage.outerHeight();
            var circleRadius = .9 * (width / 2);
            var onePoint = circleRadius / 5;
            
            var pin = (xhr[i].n == 1 ? 'red' : (xhr[i].n == 2 ? 'blue' : 'yellow'));
            $('.pie-container.' + xhr[i].c + ' .' + pin +'-pin').css('position', 'absolute');
            $('.pie-container.' + xhr[i].c + ' .' + pin +'-pin').css('left',  $pieImage.offset().left - $pieContainer.offset().left + (width / 2) + (onePoint * xhr[i].dx));
            $('.pie-container.' + xhr[i].c + ' .' + pin +'-pin').css('top',  $pieImage.offset().top - $pieContainer.offset().top + (height / 2) + (onePoint * xhr[i].dy) - ($('.pie-container.' + xhr[i].c + ' .' + pin +'-pin').height() / 2));
            //console.log('x', $pieContainer.offset().left, (width / 2) , (onePoint * xhr[i].dx));
            //console.log('y', $pieContainer.offset().top,(height / 2) ,(onePoint * xhr[i].dy));
            if(rehide) {
              $tab.css('display', 'none');
              $tab.css('visibility', 'visible');
            }
          }
        }
      })
    }
  },
  getPieColor: function($this) {
    if(!$this.hasClass('pie-container')) {
      return {
        pie: $this.closest('.pie-container').hasClass('genre') ? 'genre' : 'mood',
        color: $this.hasClass('red-pin') ? 'red' : ($this.hasClass('blue-pin') ? 'blue' : 'yellow')
      }
    } else {
      for(var pie in soundMap.statuses) {
        for(var color in soundMap.statuses[pie]) {
          if(soundMap.statuses[pie][color]) {
            return {
              pie: pie,
              color: color
            }
          }
        }
      }
      return false;
    }
  },
  startMovingPin: function($this, e) {
    var pieColor = soundMap.getPieColor($this);
    if(!soundMap.statuses[pieColor.pie][pieColor.color]) {
      $this.css('position', 'absolute');
      $this.css('top', (e.pageY - $this.closest('.pie-container').offset().top) + 'px');
      $this.css('left', (e.pageX - $this.closest('.pie-container').offset().left) + 'px');
      soundMap.statuses[pieColor.pie][pieColor.color] = true;
    }
  },
  dragPin: function($this, e) {
    var pieColor = soundMap.getPieColor($this);
    if (pieColor !== false) {
      if(soundMap.statuses[pieColor.pie][pieColor.color]) {
        // detect leaving
        if(e.pageY - $this.offset().top < 0 || e.pageY - $this.offset().top > $this.closest('.pie-container').height() ||
           e.pageX - $this.offset().left < 0 || e.pageX - $this.offset().left > $this.closest('.pie-container').width()) {
          // console.log('drop', e.pageX - $this.offset().left, e.pageY - $this.offset().top);
          this.dropPin($this, e);   
        } else {
          $('.pie-container.' + pieColor.pie + ' .' + pieColor.color + '-pin').css('top', (e.pageY - $this.offset().top) + 'px');
          $('.pie-container.' + pieColor.pie + ' .' + pieColor.color + '-pin').css('left', (e.pageX - $this.offset().left) + 'px');
        }
      }
    }
  },
  dropPin: function($this, e) {
    var pieColor = soundMap.getPieColor($this);
    // console.log(pieColor);
    if(pieColor !== false) {
      if(soundMap.statuses[pieColor.pie][pieColor.color]) {
        var $pieImage = $this.closest('.pie-container').find('.pie-selector');
        
        var objectType = $this.closest('.pie-container').data('type');
        var objectID = $this.closest('.pie-container').data('id');
        
        var $pin = $this.closest('.pie-container').find('.red-pin')
        
        var posX = e.pageX - $pieImage.offset().left,posY = e.pageY - $pieImage.offset().top + ($pin.outerHeight() / 2 );
        // console.log('ystats', e.pageY , $pieImage.offset().top , ($pin.outerHeight() / 2 ));
        var width = $pieImage.outerWidth(), height = $pieImage.outerHeight();
        var distanceX = posX - (width / 2), distanceY = posY - (height / 2);
        var distance = Math.sqrt(Math.pow(distanceX, 2) + Math.pow(distanceY,2));
        var circleRadius = .9 * (width / 2);
        // console.log(circleRadius);
        var onePoint = circleRadius / 5;
        //console.log('dim', width, height);
        // console.log('pos', posX, posY);
        // console.log('distance', distanceX, distanceY);
        // console.log(distance, circleRadius);
        if(distance < circleRadius) {
          var angle = Math.asin( (distanceY / onePoint) * Math.sin(1.5708) / (distance / onePoint) ) * 180/Math.PI;
          if(distanceX >= 0 && distanceY < 0) {
            angle = 90 + angle;
          } else if(distanceX >= 0 && distanceY >= 0) {
            angle = 90 + angle;
          } else if(distanceX < 0 && distanceY >= 0) {
            angle = 270 - angle;
          } else if(distanceX < 0 && distanceY < 0) {
            angle = 270 - angle;
          }
          
          $.ajax('/' + objectType + '/pin/' + objectID, {
            method: 'POST',
            data: {
              type: pieColor.pie,
              num: pieColor.color == 'red' ? 1 : (pieColor.color == 'blue' ? 2 : 3),
              d: distance,
              op: onePoint,
              dy: distanceY,
              dx: distanceX
            },
            success: function(xhr) {
             // console.log(xhr); 
            }
          })
          
          // console.log('score!', distance / onePoint, angle );
          soundMap.statuses[pieColor.pie][pieColor.color] = false;
          // $(this).data('valuex', distanceX / onePoint);
          // $(this).data('valuey', (distanceY / onePoint) * -1);
          // soundMap.placePin($(this), distanceX / onePoint, (distanceY / onePoint) * -1);
          // console.log('Score!', distanceX / onePoint, (distanceY / onePoint) * -1);
        } else {
          soundMap.statuses[pieColor.pie][pieColor.color] = false;
          $('.pie-container.' + pieColor.pie + ' .' + pieColor.color + '-pin').css('position', 'relative');
          $('.pie-container.' + pieColor.pie + ' .' + pieColor.color + '-pin').css('top', '50%');
          $('.pie-container.' + pieColor.pie + ' .' + pieColor.color + '-pin').css('left', '50%');
          
          $.ajax('/' + objectType + '/deletePin/' + objectID, {
            method: 'POST',
            data: {
              type: pieColor.pie,
              num: pieColor.color == 'red' ? 1 : (pieColor.color == 'blue' ? 2 : 3),
            },
            success: function(xhr) {
             // console.log(xhr); 
            }
          }) 
        }
      }
    } 
  }, 
  placePin: function($container, x, y) {
    $('.pin-' + $container.data('id')).remove();
    var offsetLeft = $container.position().left, offsetTop = $container.position().top;
    var width = $container.outerWidth();
    var circleRadius = .9 * (width / 2);
    var onePoint = circleRadius / 5;
    var picOffsetX = -20, picOffsetY = -40;
    
    var posX = offsetLeft + (width / 2) + (x * onePoint) + picOffsetX, posY = offsetTop + (width / 2) + (-1 * y * onePoint)+ picOffsetY;
      
    $container.closest('.pie-container').append('<img src="/img/red-pin2.png" class="red-pin pin-'+ $container.data('id') + '" style="top: ' +posY+ 'px; left: '+posX+'px" />' );
  },
  init: function() {
    
    $('body').on('mousedown', '.pin', function(e) {
      e.preventDefault();
      soundMap.startMovingPin($(this), e);
    });
    $('body').on('mousemove', '.pie-container', function(e) {
      e.preventDefault();
      soundMap.dragPin($(this), e);
    });
    
    $('body').on('mouseup', '.pie-container', function(e) {
      e.preventDefault();
      soundMap.dropPin($(this), e);
    });
    $('body').on('mouseleave', '.pie-container', function(e) {
      e.preventDefault();
      soundMap.dropPin($(this), e);
    });
    /*
    $('body').on('mouseleave', '.pin', function(e) {
      e.preventDefault();
      soundMap.dropPin($(this), e);
    });
    */
    this.onload();
  },
  onload: function() {
    
  }
};

module.exports = soundMap;