var uploadFileModal = {
  objID: false,
  objType: false,
  imageType: false,
  updateTarget: false,
  uploadedImageUrl: false,
  objectImageType: false,
  imageID: false,
  sizes: {},
  callback: false,
  
  init: function() {
    // show modal
    $('body').on('click', '.upload-file', function(e) {
        e.preventDefault();
        uploadFileModal.objID = $(this).data('objectid');
        uploadFileModal.objType = $(this).data('objecttype');
        if($(this).data('updatetarget')) {
          uploadFileModal.updateTarget = $(this).data('updatetarget');
        }
        uploadFileModal.imageID = false;
        uploadFileModal.uploadedImageUrl = false;
        
        $('#addFileModal').modal('show');
    });
    
    // upload 
    $('#addFileModal-upload').on('click', function(e) {
      $('#addFileModal .spinner').show();
      var data = new FormData();
      $.each($('#addFileModal-file')[0].files, function(i, file) {
          data.append('images', file);
      });
      data.append('entity_type', uploadFileModal.objType);
      data.append('entity_type_id', uploadFileModal.objID);
      
      $.ajax({
        url: '/image/uploadFile',
        data: data,
        cache: false,
        contentType: false,
        processData: false,
        type: 'POST',
        success: function(xhr){
          $('#addFileModal form').trigger("reset");
          $('#addFileModal .spinner').hide();
          // console.log(xhr);
          uploadFileModal.uploadedImageUrl = xhr.url;
          uploadFileModal.imageID = xhr.uniqueID;
          if(uploadFileModal.callback) {
            uploadFileModal.callback(xhr.uniqueID);
          }
          $('#addFileModal').modal('hide');
        }
      });
    });
      
  }
}

module.exports = uploadFileModal;