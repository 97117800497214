var addPartnerModal = {
  updateItem: false,
  init: function() {
    $('#addPartnerModal-commissionSplitFlag').change(function(e) {
      e.preventDefault();
      if($('#addPartnerModal-commissionSplitFlag').val() == 1) {
        $('.commission-split-percent-container').show();
      } else {
        $('.commission-split-percent-container').hide();
      }
    });
    
    $('#addPartnerModal-publishingSplitFlag').change(function(e) {
      e.preventDefault();
      if($('#addPartnerModal-publishingSplitFlag').val() == 1) {
        $('.publishing-split-percent-container').show();
      } else {
        $('.publishing-split-percent-container').hide();
      }
    });
    
    $('#partnership-checkbox').change(function(e) {
      e.preventDefault();
      if($('#partnership-checkbox').is(':checked')) {
        $('#addPartnerModal-add').prop('disabled', false);
      } else {
        $('#addPartnerModal-add').prop('disabled', true);
      }
    });
    
    $('#addPartnerModal-add').on('click', function(e) {
      var which = $(this).attr('id');
      e.preventDefault();
        var validator = $('#addAlbumModal-form').validate({
        highlight: function (element, errorClass, validClass) {
          var elem = $(element);
          if (elem.hasClass("select2-hidden-accessible")) {
              elem.parent().find('.select2-selection').addClass(errorClass);
              // $("#s2id_" + elem.attr("id") + " ul").addClass(errorClass);
          } else {
              elem.addClass(errorClass);
          }
        },
    
        unhighlight: function (element, errorClass, validClass) {
          var elem = $(element);
          if (elem.hasClass("select2-hidden-accessible")) {
              elem.parent().find('.select2-selection').removeClass(errorClass);
              // $("#s2id_" + elem.attr("id") + " ul").removeClass(errorClass);
          } else {
              elem.removeClass(errorClass);
          }
        }
      });
      if(validator.form()) {
        $.ajax({
          url: '/partner/add/' + addPartnerModal.updateItem,
          data: {
            subscriber_id: $('#addPartnerModal-subscriberID').val(),
            commission_split_flag: $('#addPartnerModal-commissionSplitFlag').val(), 
            commission_split_percent: $('#addPartnerModal-commissionSplitPercent').val(), 
            publishing_split_flag: $('#addPartnerModal-publishingSplitFlag').val(),
            publishing_split_percent: $('#addPartnerModal-publishingSplitPercent').val(),
            exclusive_ok_flag: $('#addPartnerModal-exclusiveOkFlag').val(),
            stems_available_flag: $('#addPartnerModal-stemsAvailableFlag').val(),
            notes: $('#addPartnerModal-notes').val()
          },
          type: 'POST',
          success: function(data){
            if(data.error) {
              $('#addPartnerModal-error').html(data.error);
              $('#addPartnerModal-error').css('display', 'block');
            } else {
              $('#addPartnerModal-error').css('display', 'none');
              $('#addPartnerModal-form')[0].reset(); 
              $("#addPartnerModal-subscriberID").val(null).trigger("change");
              $("#addPartnerModal-add").prop('disabled', true);
              // console.log(data);
              if(addPartnerModal.updateItem) {
                $.ajax({
                  url: '/partners/list/' + addPartnerModal.updateItem,
                  success: function(data) {
                    if(data.partners.length == 0) {
                      $('#partner-list').html("<li>This collection isn't currently shared with any partners. Click the button above to add some.</li>");
                    } else {
                      var source = $("#partner-template").html();
                      var template = Handlebars.compile(source);
                      $('#partner-list').html(template(data));
                    }
                    window.location = '/collection/editPrivate/' + addPartnerModal.updateItem + '#partners';
                    addPartnerModal.updateItem = false;
                  }
                });
              }
              $('#addPartnerModal').modal('hide');
            }
          }
        });
      }
    });
    // quick add form
    $.fn.modal.Constructor.prototype.enforceFocus = function() {};
    $("#addPartnerModal-subscriberID").select2({
      tags: false,
      allowClear: true,
      placeholder: 'Enter a partner name',
      minimumInputLength: 2,
      ajax: {
        url: "/subscribers/list",
        dataType: 'json',
        delay: 250,
        data: function (params) {
          return {
            q: params.term, // search term
            page: params.page
          };
        },
        processResults: function (data, params) {
          // parse the results into the format expected by Select2
          // since we are using custom formatting functions we do not need to
          // alter the remote JSON data, except to indicate that infinite
          // scrolling can be used
          params.page = params.page || 1;
          
    
          return {
            results: data,
            pagination: {
              more: (params.page * 20) < data.total_count
            }
          };
        },
        cache: true
      },
      escapeMarkup: function (markup) { return markup; }, // let our custom formatter work
      
    });
    // to make validation errors shouw up correctly
    setTimeout(function() {
      $('#addPartnerModal .select2-container').insertBefore($('#addPartnerModal-subscriberID'));
    }, 200);
  }
}

module.exports = addPartnerModal;