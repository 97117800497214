var manageAudioController = {
  init: function() {
    
    $('body').on('click', '.update-nested-track', function(e) {
      e.preventDefault();
      window.modals.updateNestedTrack.initVars({track_id: $(this).data('uniqueid')});
    });

    $('body').on('click', '.edit-track', function(e) {
      e.preventDefault();
      // var albumID = $(this).data('albumid')
      window.jsFiles.updateTrackModal.openModal($(this).data('trackid'), function() {
        
      });
    });
    
    $('.page-manage-audio').on('click', '.btn.delete-track', function(e) {
      if(confirm('Are sure you want to delete this track?')) {
        var $rowToRemove = $(this).closest('tr');
        $.ajax('/track/delete/' + $(this).data('trackid'), {
          method: 'post',
          success: function(xhr) {
            $rowToRemove.remove();  
          }
        });    
      }
    });

    manageAudioController.onload();  
      
  },
  onload: function() {
    if($('.page-manage-audio').length > 0) {
      $('.tracks-missing-audio').on( 'preXhr.dt', function(e, settings, data) {
        data['search_field'] = $('input[name="search_field"]').val();
        data['all_tracks_flag'] = $('select[name="all_tracks_flag"]').val();
      }).DataTable({
        "PaginationType": "full_numbers", 
        responsive: true,
        serverSide: true, 
        language: {
            "emptyTable":     "No data"
        },
        stateSave: true,
        stateSaveCallback: function(settings,data) {
            // localStorage.setItem( 'DataTables_' + settings.sInstance, JSON.stringify(data) )
            var urlParams = new URLSearchParams(window.location.search);
            let state = urlParams.get('state');
            let saveState = true;
            // add the filters
            if($('.filter-form').length > 0) {
              var fields = $tab.find('.filter-form input, .filter-form select');
              let filterFields = {};
              for(var j = 0; j < fields.length; j++) {
                if($(fields[j]).attr('name')) {
                  filterFields[$(fields[j]).attr('name')] = $(fields[j]).val();
                }
              }
              data.filterFields = filterFields;
            }
            if(state) {
              let stateJson = JSON.parse(state), newData = data;
              delete stateJson.time;
              let time = newData.time;
              delete newData.time;
              if(JSON.stringify(newData) == JSON.stringify(stateJson)) {
                saveState = false;
              }
              data.time = time;
              // console.log('should I save?', saveState, settings, newData, stateJson);
            }
             
            if(saveState) {
              // console.log('pushing state', data.start);
              history.pushState({}, $('title').text(), document.location.pathname + '?state=' + JSON.stringify(data));   
            }
        },
        stateLoadCallback: function(settings) {
          var urlParams = new URLSearchParams(window.location.search);
          let state = urlParams.get('state');
          if(state) {
            let stateJson = JSON.parse(state);
            // console.log('loading state. start index:', stateJson.start, settings)
            
            if(stateJson.filterFields) {
              for(let name in stateJson.filterFields) {
                $('.filter-form .form-control[name="'+name+'"]').val(stateJson.filterFields[name]);
              }
            }
            
            return stateJson;
          } else {
            //console.log('no state to load');
            return false;
          }
          
        },
        "searching": false,
        "lengthMenu": [[ 25, 50, -1], [25, 50, "All"]],
        order: [[4, 'desc']],
        dom: '<"tbl-top clearfix"lfr>,t,<"tbl-footer clearfix"<"tbl-info pull-left"i><"tbl-pagin pull-right"p>>',
        ajax: '/tracks/getTracksMissingAudio',
        columns:  [{ "data": "icon", "orderable":false },{ "data": "name" },{ "data": "artist_name" },{ "data": "track_filename" },{ "data": "create_date" },{"data" : "delete_link", "orderable":false}], 
        createdRow: function ( row, data, index ) {
           $(row).addClass('playlist-track')
        },
      });
      $('input[name="search_field"], select[name="all_tracks_flag"]').on('change', function(e) {
        $('.tracks-missing-audio').DataTable().draw();
      });
      
      
      try {
        window.initDropzone('#manage-files-dropzone', {source: 'manageAudio'}, function(dz, file) {
          // $('.tracks-missing-audio').DataTable().draw();
          $('input[mane="search_field"]').trigger('change');
          dz.removeFile(file);
          toastr.success('Track uploaded!');
        }, 'audio/*');
        /*
        $('#manage-files-dropzone').dropzone({ 
          url: "/track/upload",
          parallelUploads: 4,
          acceptedFiles: 'audio/*',
          dictDefaultMessage: 'Drop tracks here',
          paramName: 'tracks',
          // uploadMultiple: true
          addRemoveLinks: true,
          timeout: 300000,
          params: {
            source: 'manageAudio'
          },
          init: function() {
            var myDropzone = this;
            this.on('addedfile', function(file, xhr, formData) {
              window.pageChangeWarning = function() {
                if(confirm("Leaving this page will cancel uploads. Are you sure?")) {
                  myDropzone.removeAllFiles(true);
                  delete window.pageChangeWarning;
                  return true;
                } else {
                  return false;
                }
              }
              // console.log('addedfile', file, myDropzone);
            });
            this.on('queuecomplete', function() {
              delete window.pageChangeWarning;
            });
            this.on('complete', function(xhr) {
              var response = JSON.parse(xhr.xhr.response);
              $('.tracks-missing-audio').DataTable().draw();
              myDropzone.removeFile(xhr);
              toastr.success('Track uploaded!');
            });
            this.on('error', function(xhr, errorMessage) {
              myDropzone.removeFile(xhr);
              var response = JSON.parse(xhr.xhr.response);
              if(errorMessage != 'Upload canceled.') {
                toastr.error(errorMessage);
              }
            });
          }
        });
        */
      } catch(e) {
        
      }
    }
    
  }
}
module.exports = manageAudioController;