// $(document).ready(function(){
var outsideController = {
  init: function() {
    // enable popovers
    $('[data-toggle="popover"]').popover();
    
    $('body').on('click', '.unsubscribe-submit', function(e) {
      e.preventDefault();
      if($('input[name="email"]').val() != '') {
        $.ajax('/unsubscribe', {
          method: 'post',
          data: {
            email: $('input[name="email"]').val()
          },
          success: function(xhr) {
            console.log(xhr);
            $('.unsubscribe-success').hide();
            $('.unsubscribe-error').hide();
            if(xhr.success) {
              $('.unsubscribe-success').html(xhr.success);
              $('.unsubscribe-success').show();  
            } else {
              $('.unsubscribe-error').html(xhr.error);
              $('.unsubscribe-error').show();  
            }
          }
        })
      }
    });
    
    // fix dismissing them
    $('body').on('click', function (e) {
      //did not click a popover toggle or popover
      if (!$(e.target).hasClass('add-to-cart')
        && $(e.target).parents('.popover').length === 0) { 
        $('span.add-to-cart').popover('hide');
      }
    });
    // the cart selects
    $('body').on('change', '.license-select', function(e) {
      $(this).parent().find('.lic-inst').hide();
      $(this).parent().find('.li-' + $(this).data('track') + '-' +$(this).val()).show();
      $(this).parent().find('.snipcart-add-item').hide();
      $(this).parent().find('.b-' + $(this).data('track') + '-' +$(this).val()).show();
    });
    $('body').on('click', '.snipcart-add-item', function(e) {
      $('span.add-to-cart').popover('hide');
      
    });
    $('body').on('click', '.download-all',  function() {
      $.ajax(document.location.pathname + '/download', {
        data: {
          tabguid: $('body').data('tabguid')
        },
        success: function(xhr) {
          if(xhr.url) {
            document.location = xhr.url + '?' + new Date().getTime();
          } else if(xhr.error) {
            toastr.error(xhr.error);
          } else {
            toastr.success('Your download will begin shortly!');
            $('#pending-downloads ul').append('<li id="playlist-' +xhr.key+ '">' +xhr.filename+ '<span>Building ZIP...</li>');
            $('#pending-downloads').removeClass('hidden');
            $('#pending-downloads').removeClass('minimized'); 
            
            // add a listener for socketio
            window.socketIO.on('/playlistDownload/' + xhr.key, function (data) {
              if(data.status == 'download-ready') {
                if($('#pending-downloads ul #playlist-' + data.key).length > 0) {
                  $('#pending-downloads ul #playlist-' + data.key).remove();
                  if($('#pending-downloads ul li').length ==  0) {
                    $('#pending-downloads').addClass('hidden');
                  }
                  document.location = data.url + '?' + new Date().getTime();   
                  
                }
              } else {
                if($('#pending-downloads ul #playlist-' + data.key).length > 0) {
                  $('#pending-downloads ul #playlist-' + data.key).remove();
                  if($('#pending-downloads ul li').length == 0) {
                    $('#pending-downloads').addClass('hidden');
                  }
                  toastr.error('The zip "' + data.file + '" could not be built. Please try again later.');
                }
              }
              window.socketIO.off('/playlistDownload/' + data.key);
            });
          }
        }
      })
      /*
      var key = Math.random().toString(36).substring(32);
      $('#pending-downloads ul').append('<li id="playlist-' + key+ '">' + $(this).data('name') + '<span>Building ZIP...</span></li>');
      $('#pending-downloads').removeClass('hidden');
      $('#pending-downloads').removeClass('minimized'); 
      
      $.fileDownload(document.location.pathname + '/download', {
        successCallback: function(url) {
          if($('#pending-downloads ul #playlist-' + key).length > 0) {
            $('#pending-downloads ul #playlist-' + key).remove();
            if($('#pending-downloads ul li').length ==  0) {
              $('#pending-downloads').addClass('hidden');
            }
          }
          // $preparingFileModal.dialog('close');
        },
        failCallback: function(responseHtml, url) {
          if($('#pending-downloads ul #playlist-' + key).length > 0) {
            $('#pending-downloads ul #playlist-' + key).remove();
            if($('#pending-downloads ul li').length ==  0) {
              $('#pending-downloads').addClass('hidden');
            }
          }
          toastr.error('There was an error fetching your zip. Please try again later.');
        }
      });
      return false; //this is critical to stop the click event which will trigger a normal file download!
      */
      // document.location = document.location.pathname + '/download';
      // window.open(document.location.pathname + '/download', '_blank'); 
    });
    $('body').on('click', '.download-all-full',  function() {
      $.ajax(document.location.pathname + '/downloadFull', {
        data: {
          tabguid: $('body').data('tabguid')
        },
        success: function(xhr) {
          if(xhr.url) {
            document.location = xhr.url + '?' + new Date().getTime();
          } else if(xhr.error) {
            toastr.error(xhr.error);
          } else {
            toastr.success('Your download will begin shortly!');
            $('#pending-downloads ul').append('<li id="playlist-' +xhr.key+ '">' +xhr.filename+ '<span>Building ZIP...</li>');
            $('#pending-downloads').removeClass('hidden');
            $('#pending-downloads').removeClass('minimized'); 
            
            // add a listener for socketio
            window.socketIO.on('/playlistDownload/' + xhr.key, function (data) {
              if(data.status == 'download-ready') {
                if($('#pending-downloads ul #playlist-' + data.key).length > 0) {
                  $('#pending-downloads ul #playlist-' + data.key).remove();
                  if($('#pending-downloads ul li').length ==  0) {
                    $('#pending-downloads').addClass('hidden');
                  }
                  document.location = data.url +'?' + new Date().getTime();   
                  
                }
              } else {
                if($('#pending-downloads ul #playlist-' + data.key).length > 0) {
                  $('#pending-downloads ul #playlist-' + data.key).remove();
                  if($('#pending-downloads ul li').length == 0) {
                    $('#pending-downloads').addClass('hidden');
                  }
                  toastr.error('The zip "' + data.file + '" could not be built. Please try again later.');
                }
              }
              window.socketIO.off('/playlistDownload/' + data.key);
            });
          }
        }
      })
      /*
      var key = Math.random().toString(36).substring(32);
      $('#pending-downloads ul').append('<li id="playlist-' + key+ '">' + $(this).data('name') + '<span>Building ZIP...</span></li>');
      $('#pending-downloads').removeClass('hidden');
      $('#pending-downloads').removeClass('minimized'); 
      
      $.fileDownload(document.location.pathname + '/downloadFull', {
        successCallback: function(url) {
          if($('#pending-downloads ul #playlist-' + key).length > 0) {
            $('#pending-downloads ul #playlist-' + key).remove();
            if($('#pending-downloads ul li').length ==  0) {
              $('#pending-downloads').addClass('hidden');
            }
          }
          // $preparingFileModal.dialog('close');
        },
        failCallback: function(responseHtml, url) {
          if($('#pending-downloads ul #playlist-' + key).length > 0) {
            $('#pending-downloads ul #playlist-' + key).remove();
            if($('#pending-downloads ul li').length ==  0) {
              $('#pending-downloads').addClass('hidden');
            }
          }
          toastr.error('There was an error fetching your zip. Please try again later.');
        }
      });
      return false; //this is critical to stop the click event which will trigger a normal file download!
      */
      // window.open(document.location.pathname + '/downloadFull', '_blank') 
      // document.location = document.location.pathname + '/downloadFull';
    });
    // make search run on enter
    $('body').on('keypress', '#filter-container input[name="search"], #filter-container input[name="length_min"], #filter-container input[name="length_max"]', function(e) {
      
      var keycode = (e.keyCode ? e.keyCode : e.which);
      if(keycode == '13'){
          e.preventDefault();
          $(this).trigger('change');
      }
    })
    $('body').on('click', '.send-track-message', function(e){
      window.jsFiles.trackMessage.open($(this).data('track'), $(this).data('name'), $(this).data('artist'));
    });
    $('body').on('click', '.show-nested-tracks', function(e) {
      e.preventDefault();
      e.stopPropagation();
      $(this).toggleClass('open');
      var trackID = $(this).data('track')
      let table = $('.responsive-data-table').DataTable();
      var tr = $(this).closest('tr');
      var row = table.row( tr );
      var div = $('<div />');
      var target = $('body').data('ajaxbaseurl')  + '/c/' + $('.content > .content').data('subdomain') + '/getNestedTracks/' + trackID;
      var catalogFlag = false;
      if($('.content > .content').data('subdomain')) {
        target = (document.location.host.indexOf('otis.is') > -1 ? document.location.origin :  document.location.pathname)+ '/getNestedTracks/' + trackID;
        var catalogFlag = true;
      }
      let format = function( rowData ) {
        $.ajax( {
            url: target,
            dataType: 'jsonp',
            success: function ( xhr ) {
              if(catalogFlag) {
                var source = $("#public-nested-tracks").html();
                var template = Handlebars.compile(source);
                div.html(template(xhr));
                
                // add the popover
                div.find('.add-to-cart').popover({ 
                  html : true,
                  placement: 'left',
                  container: 'body',
                  trigger: 'click focus',
                  title: function() {
                    return 'Purchase License';
                  },
                  content: function() {
                    var trackID = $(this).parent().data('track');
                    var licenses = $(this).parent().data('licenses');
                    var trackName = $(this).parent().data('name');
                    if(licenses.length > 0) {
                      var content = '<div class="form-group"><select name="license_type" class="form-control license-select" data-track="'+trackID+'"><option value="">Choose a license</option>';
                      for(var i = 0; i < licenses.length; i++) {
                        content += '<option value="' + licenses[i].id + '">'+licenses[i].name+': $'+licenses[i].price.toFixed(2)+'</option>';
                      }
                      content += '</select>';
                      for(var i = 0; i < licenses.length; i++) {
                        content += '<div class="lic-inst li-'+trackID+'-' + licenses[i].id + '">'+licenses[i].description+'</div>';
                      }
                      for(var i = 0; i < licenses.length; i++) {
                        content += `<button
                            class="btn btn-primary btn-sm snipcart-add-item b-`+trackID+`-`+ licenses[i].id +`"
                            data-item-id="`+trackID+`-`+ licenses[i].id +`-` + $('.content[data-subdomain]').data('subdomain') +`"
                            data-item-name="`+trackName+`"
                            data-item-description="`+licenses[i].name+` License"
                            data-item-price="`+ licenses[i].price +`"
                            data-item-custom1-name="Client Name"
                            data-item-custom1-required="true"
                            data-item-custom2-name="Production Title"
                            data-item-custom2-required="true"
                            data-item-custom3-name="Production Description"
                            data-item-custom3-required="true"
                            data-item-custom4-name="Production Date"
                            data-item-custom4-required="false"
                            data-item-custom5-name="Production Length"
                            data-item-custom5-required="false"
                            data-item-url="https://otis.is/scproducts/`+$('.content[data-subdomain]').data('subdomain')+`"
                            >
                            Add to Cart
                        </button>`;              
                        
                      }
                      content += '</div>';
                      return content;
                    } else {
                      return '<div class="form-group"><button class="btn btn-default modal-edit" data-modal="addPlaylist" data-track_id="'+trackID+'">New Playlist</button></div>';
                    }
                    /*
                    var trackID = $(this).data('trackid');
                    $.ajax('/my-tracks/getPlaylistsWithoutTrack/' + trackID, {
                      success: function(xhr) {
                        console.log(xhr);
                        return '<div class="form-group"><select class="form-control"><option>--</option><option>Playlist 1</option><option>Playlist 2</option></select><input type="hidden" value="'+trackID+'"><button class="btn btn-default">Add</button></div>';
                      }
                    });
                    */
                    
                  }
                });
              } else {
                var source = $("#favorite-nested-tracks").html();
                var template = Handlebars.compile(source);
                div.html(template(xhr));
              }
              // resize the rows
              $(div).find('table tr:first-child td').each(function(i, elt) {
                $(elt).css('width', $($('.responsive-data-table tbody tr:first-child td')[i]).width() + 'px');
              });
            }
        } );
     
        return div;
      }
      
      if ( row.child.isShown() ) {
          row.child.hide();
          tr.removeClass('shown');
      } else {
          row.child( format(row.data()) ).show();
          tr.addClass('shown');
      }
    })
    outsideController.onload();
  },
  onload: function() {
    // $('.menu-overlay').trigger('click');
    $('.show-request-filters').on('click', function(e) {
      if($(this).hasClass('open')) {
        $(this).html('Show Filters &#9654;');
        $(this).removeClass('open');
        $('#filter-container').next().removeClass('filter-open');
        $('#filter-container').removeClass('open');
      } else {
        $(this).html('&#9664; Hide Filters');
        $(this).addClass('open');
        $('#filter-container').next().addClass('filter-open');
        $('#filter-container').addClass('open');
      }
    });
    if($('body.page-pricing').length > 0) {
      outsideController.updatePricingTables();
      $(window).on('resize', function() {
        outsideController.updatePricingTables();
      });
      $('body.page-pricing button.sign-up').on('click', function(e) {
        window.openModal('#signupModal');
      })
    }
    if($('body.page-requests').length > 0) {
      var currentPage = 1;
      outsideController.initRequestDatatable();
      var updateFilters = function(nextPage) {
        if($('.masonry-view').is(':visible')) {
          var filters = {}, filterInputs = $('body.page-requests #filter-container .form-control');
          for(var i = 0; i < filterInputs.length; i++) {
            filters[$(filterInputs[i]).attr('name')] = $(filterInputs[i]).val();
          }
          if(nextPage) {
            currentPage++;
            filters.page = currentPage;
          }
          if(currentPage == 1) {
            $('.masonry').html('');
          }
          
          // handle pushstate
          if(document.location.search != '?' + $.param(filters)) {
            history.pushState({}, $('title').html(), document.location.pathname + '?' + $.param(filters)); 
          }
          $('.spinner').show();
          $.ajax('/opportunities/list', {
            data: filters,
            success: function(xhr) {
              $('.spinner').hide();
              var source = $("#request-item-template").html();
              var template = Handlebars.compile(source);
              for(var i = 0; i < xhr.data.length; i++) {
                $('.masonry').append(template(xhr.data[i]));
              }
              $('.masonry .header-image h3').dotdotdot({
                height: 120
              });
              if(xhr.data.length == 0) {
                $('.masonry').append('<p>No opportunities match your search.</p>');
              }
            }
          })
        } else {
          $('.page-requests .responsive-data-table').DataTable().draw();
        }
      }  
      // submit the form if they hit enter
      $(' #filter-container .form-control').on('keydown', function(e) {
          if (e.which == 13) {
            updateFilters();
          }
      });
      
      updateFilters();
      
      $('.view-chooser button.change-view').on('click', function(e) {
        e.preventDefault();
        $('.data-view').hide();
        $('.data-view.' + $(this).data('view')).show();
        $('button.change-view').removeClass('active');
        $('td.shave').dotdotdot({
          height: 100
        });
        $('#filter-container input[name="view_type"]').val($(this).data('view'));
        $(this).addClass('active');
        updateFilters(false);
      });
      $('body.page-requests .update-filter').on('click', function(e) {
        updateFilters(false);
      });
    }
    if($('body.page-request').length > 0) {
      var settings = window.config.tinymce;
      settings.setup = function(ed) {
        // console.log('in init');
        ed.on('blur', function(e) {
          $('body.page-request textarea.wysiwyg-editor').val(ed.getContent());
          $('body.page-request textarea.wysiwyg-editor').trigger('change');
        });    
      }
      tinymce.init(settings);
      try {
        window.initDropzone('#response-dropzone', {source: 'request'}, function(dz, file, data) {
          toastr.success('Track uploaded!');
          // console.log('file', file, JSON.parse(file.xhr.responseText));
          dz.removeFile(file);
          $.ajax('/opportunities/response/addTrack', {
            method: 'POST',
            data: {
              track_id: data.trackID,
              request_id: $('.request-container').data('uniqueid') 
            },
            complete: function(xhr) {
              outsideController.updateTrackList();
            }
          })
        }, 'audio/*');
        /*
        $('#response-dropzone').dropzone({ 
          url: "/track/upload",
          parallelUploads: 4,
          acceptedFiles: 'audio/*',
          dictDefaultMessage: 'Drag tracks here to upload',
          paramName: 'tracks',
          // uploadMultiple: true
          addRemoveLinks: true,
          timeout: 300000,
          params: {
            source: 'request',
          },
          init: function() {
            var myDropzone = this;
            this.on('addedfile', function(file, xhr, formData) {
              window.pageChangeWarning = function() {
                if(confirm("Leaving this page will cancel uploads. Are you sure?")) {
                  myDropzone.removeAllFiles(true);
                  delete window.pageChangeWarning;
                  return true;
                } else {
                  return false;
                }
              }
            });
            this.on('queuecomplete', function() {
              delete window.pageChangeWarning;
            });
            this.on('complete', function(file) {
              toastr.success('Track uploaded!');
              // console.log('file', file, JSON.parse(file.xhr.responseText));
              myDropzone.removeFile(file);
              $.ajax('/opportunities/response/addTrack', {
                method: 'POST',
                data: {
                  track_id: JSON.parse(file.xhr.responseText).trackID,
                  request_id: $('.request-container').data('uniqueid') 
                },
                complete: function(xhr) {
                  outsideController.updateTrackList();
                }
              })
            });
            this.on('error', function(xhr, errorMessage) {
              myDropzone.removeFile(xhr);
              var response = JSON.parse(xhr.xhr.response);
              if(errorMessage != 'Upload canceled.') {
                toastr.error(errorMessage);
              }
            });
          }
        });
        */
      } catch(e) {
        console.log('dzerror', e);
      }
      // get the current tracks
      outsideController.updateTrackList();
      // setup the track chooser
      if($('.track-chooser').length > 0) {
        window.trackChooser.init('.track-chooser', '/opportunities/' + $('.request-container').data('uniqueid') + '/saveTracks')
      }
      $('.response-container').on('click', '.edit-track', function(e) {
        e.stopPropagation();
        e.preventDefault();
        window.jsFiles.updateTrackModal.openModal($(this).data('trackid'), function() { });
      });
      $('.response-container').on('click', '.add-playlist-track', function(e) {
        e.preventDefault();
        let $this = $(this);
        $.ajax('/opportunities/response/deleteTrack', {
          method: 'POST',
          data: {
            track_id: $this.closest('li').data('objectid'),
            request_id: $('.request-container').data('uniqueid') 
          },
          complete: function(xhr) {
            outsideController.updateTrackList();
          }
        })
      });
      $('.submit-response').on('click', function(e) {
        e.preventDefault();
        $.ajax('/opportunities/'+ $('.request-container').data('uniqueid') + '/submit', {
          method: 'POST',
          success: function(xhr) {
            toastr.success('The response was submitted!');
            $('.submit-container').html('<p>Tracks submitted. You can still edit your message and uploaded tracks if needed.</p>');
            $('.free-upgrade-tease').show();
          }
        });
      });
      $('body.page-request textarea.wysiwyg-editor').on('change', function(e) {
        var $this = $(this);
        $.ajax('/opportunities/' + $('.request-container').data('uniqueid') + '/update', {
          method: 'POST',
          data: {
            field: 'description',
            value: $this.val()
          },
          success: function(xhr) {
            toastr.success('Updated!');
          }
        });
      });
      $('.request-login').on('click', function(e) {
        $('#loginModal-redirect').val($('.request-container').data('redirecturl'));  
        $('#loginModal').modal('show');
      }); 
      $('.request-signup').on('click', function(e) {
        $('#signupModal-redirect').val($('.request-container').data('redirecturl'));  
        $('#signupModal').modal('show');
      });
      $('.start-response').on('click', function(e) {
        $('.login-submit-container').hide();
        $('.submission-container').show();
      });
    }
    if($('body.page-playlist').length > 0) {
      $('.submit-password').on('click', function(e) { 
        e.preventDefault();
        var action = $(this).closest('form').attr('action');
        $.ajax(action, {
          method: 'POST',
          data: {
            playlist_password: $('input[name="playlist_password"]').val()
          },
          success: function(xhr) {
            if(xhr.success) {
              window.goToUrl(document.location.pathname);
            } else {
              $('#playlistpassword-error').html(xhr.error);
              $('#playlistpassword-error').show();
            }
          }
        })
      })
      $('.playlist-holder').on('click', 'tr[data-objecttype="folder"]', function(e) {
        var $this = $(this);
        var folderID = $this.data('objectid');
        // console.log($this);
        if(!$this.hasClass('expanded')) {
          
          $.ajax(document.location.pathname, {
            data: {
              folder_id: folderID
            },
            success: function(xhr) {
              //console.log(xhr);
              $this.addClass('expanded');
              window.jsFiles.player.checkPlayingTrack();
              var source = $("#folder-detail").html();
              var template = Handlebars.compile(source);
              var html = template(xhr);
              $('tr.folder-row[data-objectid="' + folderID + '"] > td > div').html(html);
              $('tr.folder-row[data-objectid="' + folderID + '"]').show();
              //window.trackChooser.processTrackList(xhr, true, '.album-tracks li.folder[data-objectID="'+folderID+'"]', '<ul class="child-folder" data-folderid="'+folderID +'">', 'ul.child-folder[data-folderid="'+folderID+'"]');
              
              /*
              if(!window.trackChooser.folderHoverFoldersActivated) {
                window.trackChooser.setupFolderTargets();
                window.trackChooser.folderHoverFoldersActivated = true;
              }
              */
            }
          });
        } else {
          $this.removeClass('expanded');
          $('tr.folder-row[data-objectid="' + folderID + '"]').hide();
          $('tr.folder-row[data-objectid="' + folderID + '"] > td > div').html('');
        }
        e.stopPropagation();
      });
    } 
    if($('body').hasClass('page-catalog')) {
      outsideController.initCatalogPage($('body').data('ajaxbaseurl') + '/c/' + $('.content > .content').data('subdomain') + '/data',function(data) {
        if(data.user.emailAddress) {
          $('.request-download-access').on('click', function(e) {
            var $this = $(this);
            $.ajax({
              url: $('body').data('ajaxbaseurl')  + '/requestDownloadAccess/' + $('.content > .content').data('subdomain'),
              method: 'get',
              dataType: "jsonp",
              success: function(data) {
                if(data.login) {
                  $('.modal').modal('hide');
                  $('#loginModal').modal('show');
                } if(data.reload) { 
                  $this.remove();
                  $('input.form-control[name="search"]').trigger('change');
                } else {
                 $this.html('Download Access Requested')
                 $this.removeClass('request-download-access');
                }
              }
            })
          })
        } else {
          $('.request-download-access').on('click', function(e) {
            document.location = $('body').data('ajaxbaseurl') + '/login?message=download-access&redirect=' + document.location ;
          })
        }
        var refreshCounts = function() {
          $.ajax($('body').data('ajaxbaseurl') + '/c/' + $('.content > .content').data('subdomain') + '/getFilters', {
            method: 'get',
            dataType: "jsonp",
            data: {
              filters: JSON.stringify(outsideController.getTrackFilters())
            }, 
            success: function(xhr) {
              $('.track-counts').html('(0)');
              for(var key in xhr) {
                for(var i = 0; i < xhr[key].length; i++) {
                  $('#tc-'+key+'-' + (xhr[key][i].slug ? xhr[key][i].slug : xhr[key][i].value)).html('('+xhr[key][i].count+')');
                }
              }
            }
          })
        }
        refreshCounts();
        outsideController.initCatalogTrackDatatable();
        $('.filter-form input').on('change', function(e) {
          switch($(this).attr('name')) {
            case 'artist':
            case 'cue_type':
            case 'tempo':
              $('input[type="checkbox"][name="'+ $(this).attr('name') +'"][value!="'+$(this).val()+'"]').prop('checked', false);
              break;
          }
          refreshCounts();
          $('.page-catalog .responsive-data-table').DataTable().draw();  
        });
      });
    }
    if($('body').hasClass('page-catalog-albums')) {
      outsideController.initCatalogPage($('body').data('ajaxbaseurl') + '/c/' + $('.content > .content').data('subdomain') + '/albums/data', function(data) {
        outsideController.initCatalogAlbumDatatable();
        $('.filter-form input').on('change', function(e) {
          $('.page-catalog-albums .responsive-data-table').DataTable().draw();  
        });
      });
    }
    if($('body').hasClass('page-catalog-album')) {
      outsideController.initCatalogPage($('body').data('ajaxbaseurl') + '/c/' + $('.content > .content').data('subdomain') + '/albums/' + $('.content > .content').data('album') + '/' + $('.content > .content').data('albumname') + '/data', function(data) {
        // console.log(data);
        $('#album-cover').append('<img src="'+data.album.imageURL+'" alt="album cover">');
        $('#album-name').html(data.album.name);
        $('#album-description').html(data.album.description);
        
        if(data.album.tracks.length > 0) {
          var source = $("#album-track-template").html();
          var template = Handlebars.compile(source);
          var html = template({tracks: data.album.tracks});
          $('.request-container .playlist .playlist-holder').html(html);
        }
      });
    }
    if($('body').hasClass('page-catalog-playlists')) {
      outsideController.initCatalogPage($('body').data('ajaxbaseurl') + '/c/' + $('.content > .content').data('subdomain') + '/playlists/data', function(data) {
        // console.log(data);
        let page = 1;
        outsideController.initCatalogPlaylistsMasonry(page);
        $('#filter-container input').on('change', function(e) {
          page = 1;
          outsideController.initCatalogPlaylistsMasonry(page);
        });
        $('.playlist-pagination button.prev').on('click', function(e) {
          page--;
          outsideController.initCatalogPlaylistsMasonry(page);
        })
        $('.playlist-pagination button.prev').on('click', function(e) {
          page++;
          outsideController.initCatalogPlaylistsMasonry(page);
        });
        /*
        $('#album-cover').append('<img src="'+data.album.imageURL+'" alt="album cover">');
        $('#album-name').html(data.album.name);
        $('#album-description').html(data.album.description);
        
        if(data.album.tracks.length > 0) {
          var source = $("#album-track-template").html();
          var template = Handlebars.compile(source);
          var html = template({tracks: data.album.tracks});
          $('.playlist-holder').html(html);
        }
        */
      });
    }
  },
  updatePricingTables: function() {
    if($( window ).width() > 992) {
      let copies = $('.pricing-item .copy'), currentMax = 0;
      for(let i = 0; i < copies.length; i++) {
        if($(copies[i]).outerHeight() > currentMax) {
          currentMax = $(copies[i]).outerHeight();
        }
      }
      $('.pricing-item .copy').css('height', (currentMax + 15) + 'px');
    }
  },
  updateTrackList: function() {
    $.ajax('/opportunities/' + $('.request-container').data('uniqueid') + '/listTracks', {
      method: 'GET',
      success: function(xhr) {
        if($('#uploaded-tracks').length > 0) {
          var source = $("#track-listing-template").html();
          var template = Handlebars.compile(source);
          var html = template({tracks: xhr});
          $('#uploaded-tracks').html(html);
          if(xhr.length == 0) {
            $('.submit-response').attr('disabled', true);
          } else {
            $('.submit-response').removeAttr('disabled');
          }
        } else {
          var source = $("#track-listing-template").html();
          var template = Handlebars.compile(source);
          var html = template({tracks: xhr});
          $('.album-tracks').html(html);
          if(xhr.length == 0) {
            $('.submit-response').attr('disabled', true);
          } else {
            $('.submit-response').removeAttr('disabled');
          }
        }
        window.trackChooser.lastRefreshDate = new Date();
        Sortable.create(document.querySelector('.album-tracks ul'), {
    			sort: true,
    			filter: '.heading',
    			group: {
        		name: 'track-list',
        		pull: true,
        		put: true
        	},
    			animation: 150,
    			onStart: function (/**Event*/evt) {
            window.trackChooser.dragStartDate = new Date();
        	},
        	onSort: function (/**Event*/evt) {
          	if(window.trackChooser.lastRefreshDate !== false && window.trackChooser.lastRefreshDate > window.trackChooser.dragStartDate) {
              window.trackChooser.cancelDrag(evt);
              evt.preventDefault();
              return;
            } else {
              window.trackChooser.updateDraggedTracks(null, null, function() {
                
              });
            }
          },
    		});
      }
    })
  },
  initRequestDatatable: function() {
    if(!$.fn.dataTable.isDataTable('.page-requests .responsive-data-table')) {
      // console.log($tab.find('.filter-form').data('columns'));
      
      var target = '/opportunities/list';
      
      $.fn.dataTable.moment( 'MMM D, YYYY' );
      $('.page-requests .responsive-data-table:not(.delay-init)').on( 'preXhr.dt', function(e, settings, data) {
        var filters = {};
        if($('#filter-container').length > 0) {
          var fields = $('#filter-container input,#filter-container select');
          for(var j = 0; j < fields.length; j++) {
            if($(fields[j]).attr('name')) {
              filters[$(fields[j]).attr('name')] = $(fields[j]).val();
              data[$(fields[j]).attr('name')] = $(fields[j]).val();
            }
          }
        }
        if(document.location.search != '?' + $.param(filters)) {
          history.pushState({}, $('title').html(), document.location.pathname + '?' + $.param(filters)); 
        }
      }).DataTable({
        "PaginationType": "full_numbers", 
        responsive: true,
        serverSide: true, 
        "searching": false,
        language: { 
            "emptyTable":     "No data"
        },
        "lengthMenu": [[ 25, 50], [25, 50]],
        order: [[6, 'desc']],
        dom: '<"tbl-top clearfix"lfr>,t,<"tbl-footer clearfix"<"tbl-info pull-left"i><"tbl-pagin pull-right"p>>',
        ajax: target,
        columns:  [{"data" : "response_star", mobile: false},{ "data": "subscriber_name_link", label:'Agency/Company', mobile: true},{ "data": "request_name_link", label: 'Title', mobile: true },/*{ "data": "request_type_text", label: 'Request Type', mobile: false }, */{ "data": "description", label: 'Summary', mobile: false, className: "hidden-xs shave" },{ "data": "due_date", label: 'Due Date', mobile: false, className: "hidden-xs" },{ "data": "fee_max", label: 'Max Fee', mobile: false, className: "hidden-xs" },{ "data": "mod_date", label: 'Last Modified', mobile: false, className: "hidden-xs" }],
        createdRow: function ( row, data, index ) {
           $(row).addClass('playlist-track')
        }, 
        drawCallback: function(settings, json) {
          console.log('table loaded!')
          $('td.shave').dotdotdot({
            height: 100
          });
        }
      });
    }
  },
  getTrackFilters: function() {
    var filters = {};
    filters.search = $('#filter-container input[name="search"]').val().trim() == '' ? false : $('#filter-container input[name="search"]').val().trim();
    filters.length_min = $('#filter-container input[name="length_min"]').length > 0 ? ($('#filter-container input[name="length_min"]').val().trim() == '' ? false : $('#filter-container input[name="length_min"]').val().trim()) : false;
    filters.length_max = $('#filter-container input[name="length_max"]').length > 0 ? ($('#filter-container input[name="length_max"]').val().trim() == '' ? false : $('#filter-container input[name="length_max"]').val().trim()) : false;
    let moods = $('input[name="mood"]:checked').map(function () {
        return this.value;
    }).get();
    filters.mood = moods.length == 0 || moods[0] == '' ? false : moods;
    let genres = $('input[name="genre"]:checked').map(function () {
        return this.value;
    }).get();
    filters.genre = genres.length == 0 || genres[0] == '' ? false : genres;
    let cue_types = $('input[name="cue_type"]:checked').map(function () {
        return this.value;
    }).get();
    filters.cue_type = cue_types.length == 0 || cue_types[0] == '' ? false : cue_types;
    let tempos = $('input[name="tempo"]:checked').map(function () {
        return this.value;
    }).get();
    filters.tempo = tempos.length == 0 || tempos[0] == '' ? false : tempos;
    let artists = $('input[name="artist"]:checked').map(function () {
        return this.value;
    }).get();
    filters.artist = artists.length == 0 || artists[0] == '' ? false : artists;
    return filters;
  },
  initCatalogPage: function(url, callback) {
    $.ajax(url, {
      method: 'get',
      dataType: 'jsonp',
      success: function(data) {
        // console.log(data);
        $('#catalog-header .user-logo').attr('src', data.item.logo_url);
        if(data.item.playlist_id) {
          $('.playlist-container .left h4').html(data.item.playlist_name);
          $('.playlist-container .cover-image').css('background-image', 'url(' + data.item.playlist_url + ')');
          $('.playlist-container .cover-image .play-playlist').attr('data-playlist', data.item.playlist_id);
          $('#playlist-container-container').css('visibility', 'visible');
        }
        $('.hero .bg-image').css('background-image', 'url(' + data.item.header_url + ')');
        $('.hero .caption h3').html(data.item.name);
        if(data.item.description) {
          $('.hero .caption').append('<p>' + data.item.description + '</p>');
        }
        if(data.item.granted_flag == -1 || data.item.granted_flag == 0) {
          $('.request-download-access-container button').html('Download Access Requested');
          $('.request-download-access-container button').removeClass('request-download-access');
          $('.request-download-access-container button').show();
        } else if(data.item.granted_flag == 1) {
          $('.request-download-access-container button').hide();
        } else {
          $('.request-download-access-container button').show();
        }
        if(data.params && data.params.length_min) {
          $('input[name=length_min]').val(data.params.length_min);
        } else {
          $('input[name=length_min]').val(data.minLength);
        }
        if(data.params && data.params.length_max) {
          $('input[name=length_max]').val(data.params.length_max);
        } else {
          $('input[name=length_max]').val(data.maxLength);
        }
        if(data.filters) {
          for(var key in data.filters) {
            if(data.filters[key].type != 'hidden') {
              let temp = `
                <div class="form-group">
                  <label for="`+key+`">` + data.filters[key].label +`</label>
                  <div class="option-container border" data-field="` + key + `">`;
              for(var i = 0; i < data.filters[key].options.length; i++ ){
                temp += `
                      <div class="option">
                        <input type="checkbox" name="` + key + `" id="check-` + key + `-` + (data.filters[key].options[i].slug ? data.filters[key].options[i].slug : data.filters[key].options[i].value) + `" value="` + data.filters[key].options[i].value + `" `; 
                if(data.params && data.params[key] && data.params[key] == data.filters[key].options[i].value) { 
                  temp += 'checked';
                } else if(data.filters[key].options[i].value == '' && (!data.params || !data.params[key])) { 
                  temp+= 'checked';
                }
                temp += `> 
                        <label for="check-` + key + `-` + (data.filters[key].options[i].slug ? data.filters[key].options[i].slug : data.filters[key].options[i].value) +`" class="css-label"></label> ` + data.filters[key].options[i].text +` <span class="track-counts" id="tc-`+ key +`-`+  (data.filters[key].options[i].slug ? data.filters[key].options[i].slug : data.filters[key].options[i].value) +`">(0)</span>
                      </div>
                  `;
              }
              temp += `</div></div>`;
              $('#addl-filters').append(temp);
            }
          }
        }
        // turn on the filters
        $('.form-group label:not(.css-label)').on('click', function(e) {
          e.preventDefault();
          if($(this).hasClass('open')) {
            $('.option-container[data-field="'+$(this).attr('for')+'"]').slideUp(250);
            $(this).removeClass('open');
          } else {
            $('.option-container[data-field="'+$(this).attr('for')+'"]').slideDown(250);
            $(this).addClass('open');
          }
        });
        $('input[type="checkbox"]').on('change', function(e) {
          if($(this).val() == '') {
            $('input[name="'+$(this).attr('name')+'"]:not([value=""])').prop('checked', false); 
          } else {
            $('input[name="'+$(this).attr('name')+'"][value=""]').prop('checked', false); 
          }
          let options = $('input[name="'+$(this).attr('name')+'"]:checked').map(function () {
              return this.value;
          }).get();
          if(options.length == 0) {
            $('input[name="'+$(this).attr('name')+'"][value=""]').prop('checked', true); 
          }
        });
         
        // set up the rest of the page
        callback(data);
      }
    })
  },
  initCatalogTrackDatatable: function() {
    let cols = [
      {"data" : "play_link", mobile: false, orderable: false, className: 'td-icon'},
      { "data": "name", label:'Track Name', mobile: true, className: 'td-name last-xs'},
      { "data": "artist_name", label: 'Artist', mobile: false, className: "td-artist hidden-xs"},
      { "data": "albums", label: 'Albums', mobile: false, className: "hidden-xs shave", orderable: true },
      { "data": "genres", label: 'Genres', mobile: false, className: "hidden-xs shave", orderable: false },
      { "data": "moods", label: 'Moods', mobile: false, className: "hidden-xs shave", orderable: false },
      { "data": "track_length", label: 'Length', mobile: false, className: "hidden-xs td-length" },
      { "data": "nested_tracks", className: 'nested-tracks-expand td-icon hidden-xs', orderable: false, defaultContent: ''},
      { "data": "download_link", className: 'td-icon hidden-xs', orderable: false},
      { "data": "favorited_flag", className: 'td-icon hidden-xs', orderable: false},
      { "data": "track_message", className: 'td-icon hidden-xs', orderable: false},
      { "data": "cart_icon", className: 'td-icon hidden-xs', orderable: false},
      {visible: false, data: 'search_score'}
    ];
    if(!$.fn.dataTable.isDataTable('.page-catalog .responsive-data-table')) {
      // console.log($tab.find('.filter-form').data('columns'));
      var target = '/c/'+$('#content > .content').data('subdomain') + '/getTracks';
      
      $.fn.dataTable.moment( 'MMM D, YYYY' );
      $('.page-catalog .responsive-data-table:not(.delay-init)').on( 'preXhr.dt', function(e, settings, data) {
        /*
        if(document.location.search != '?' + $.param(filters)) {
          history.pushState({}, $('title').html(), document.location.pathname + '?' + $.param(filters)); 
        }
        */
        let filters = outsideController.getTrackFilters();
        data.filters = JSON.stringify(filters);
      }).DataTable({
        "PaginationType": "full_numbers", 
        responsive: true,
        serverSide: true, 
        "searching": false,
        language: {
            "emptyTable":     "No data"
        },
        "lengthMenu": [[ 25, 50], [25, 50]],
        order: [[cols.length - 1, 'desc']],
        dom: '<"tbl-top clearfix"lfr>,t,<"tbl-footer clearfix"<"tbl-info pull-left"i><"tbl-pagin pull-right"p>>',
        ajax: target,
        ajax: {
            "url": $('body').data('ajaxbaseurl') + target,
            "dataType": "jsonp"
        },
        columns:  cols,
        createdRow: function ( row, data, index ) {
           $(row).addClass('playlist-track')
        }, 
        drawCallback: function(settings, json) {
          console.log('table loaded!');
          window.jsFiles.player.checkPlayingTrack();
          
          $('td.shave').dotdotdot({
            height: 100
          });
          $('.responsive-data-table').find('.add-to-cart').popover({ 
            html : true,
            placement: 'left',
            container: 'body',
            trigger: 'click focus',
            title: function() {
              return 'Purchase License';
            },
            content: function() {
              var trackID = $(this).parent().data('track');
              var licenses = $(this).parent().data('licenses');
              var trackName = $(this).parent().data('name');
              if(licenses.length > 0) {
                var content = '<div class="form-group"><select name="license_type" class="form-control license-select" data-track="'+trackID+'"><option value="">Choose a license</option>';
                for(var i = 0; i < licenses.length; i++) {
                  content += '<option value="' + licenses[i].id + '">'+licenses[i].name+': $'+licenses[i].price.toFixed(2)+'</option>';
                }
                content += '</select>';
                for(var i = 0; i < licenses.length; i++) {
                  content += '<div class="lic-inst li-'+trackID+'-' + licenses[i].id + '">'+licenses[i].description+'</div>';
                }
                for(var i = 0; i < licenses.length; i++) {
                  content += `<button
                      class="btn btn-primary btn-sm snipcart-add-item b-`+trackID+`-`+ licenses[i].id +`"
                      data-item-id="`+trackID+`-`+ licenses[i].id +`-` + $('.content[data-subdomain]').data('subdomain') +`"
                      data-item-name="`+trackName+`"
                      data-item-description="`+licenses[i].name+` License"
                      data-item-price="`+ licenses[i].price +`"
                      data-item-custom1-name="Client Name"
                      data-item-custom1-required="true"
                      data-item-custom2-name="Production Title"
                      data-item-custom2-required="true"
                      data-item-custom3-name="Production Description"
                      data-item-custom3-required="true"
                      data-item-custom4-name="Production Date"
                      data-item-custom4-required="false"
                      data-item-custom5-name="Production Length"
                      data-item-custom5-required="false"
                      data-item-url="https://otis.is/scproducts/`+$('.content[data-subdomain]').data('subdomain')+`"
                      >
                      Add to Cart
                  </button>`;              
                  
                }
                content += '</div>';
                return content;
              } else {
                return '<div class="form-group"><button class="btn btn-default modal-edit" data-modal="addPlaylist" data-track_id="'+trackID+'">New Playlist</button></div>';
              }
              /*
              var trackID = $(this).data('trackid');
              $.ajax('/my-tracks/getPlaylistsWithoutTrack/' + trackID, {
                success: function(xhr) {
                  console.log(xhr);
                  return '<div class="form-group"><select class="form-control"><option>--</option><option>Playlist 1</option><option>Playlist 2</option></select><input type="hidden" value="'+trackID+'"><button class="btn btn-default">Add</button></div>';
                }
              });
              */
              
            }
          });
        }
      });
    }
  },
  initCatalogAlbumDatatable: function() {
    let cols = [
      { "data": "cover_image", label:'Album Cover', mobile: false, orderable: false},
      { "data": "name", label:'Track Name', mobile: true, className: 'td-name'},
      { "data": "artists", label: 'Artists', mobile: false, className: 'hidden-xs shave'},
      { "data": "track_count", label: 'Tracks', mobile: false, className: "hidden-xs"},
      { "data": "description", label: 'Description', mobile: false, className: "hidden-xs", orderable: false },
      { "data": "last_updated", label: 'Last Updated', mobile: false, className: "hidden-xs" },
      { "data" : "play_link", mobile: false, orderable: false, className: 'td-icon'},
      
    ];
    if(!$.fn.dataTable.isDataTable('.page-catalog .responsive-data-table')) {
      // console.log($tab.find('.filter-form').data('columns'));
      var target = '/c/'+$('#content > .content').data('subdomain') + '/getAlbums';
      
      $.fn.dataTable.moment( 'MMM D, YYYY' );
      $('.page-catalog-albums .responsive-data-table:not(.delay-init)').on( 'preXhr.dt', function(e, settings, data) {
        /*
        if(document.location.search != '?' + $.param(filters)) {
          history.pushState({}, $('title').html(), document.location.pathname + '?' + $.param(filters)); 
        }
        */
        let filters = outsideController.getTrackFilters();
        data.filters = JSON.stringify(filters);
      }).DataTable({
        "PaginationType": "full_numbers", 
        responsive: true,
        serverSide: true, 
        "searching": false,
        language: {
            "emptyTable":     "No data"
        },
        "lengthMenu": [[ 25, 50], [25, 50]],
        order: [[cols.length - 1, 'desc']],
        dom: '<"tbl-top clearfix"lfr>,t,<"tbl-footer clearfix"<"tbl-info pull-left"i><"tbl-pagin pull-right"p>>',
        ajax: target,
        ajax: {
            "url": $('body').data('ajaxbaseurl') + target,
            "dataType": "jsonp"
        },
        columns:  cols,
        createdRow: function ( row, data, index ) {
           $(row).addClass('playlist-track')
        }, 
        drawCallback: function(settings, json) {
          console.log('table loaded!')
          window.jsFiles.player.checkPlayingTrack();
          $('td.shave').dotdotdot({
            height: 100
          });
        }
      });
    }
  },
  initCatalogPlaylistsMasonry: function(page) {
    if(!page)  page = 1; 
    let filters = outsideController.getTrackFilters();
    var target = '/c/'+$('#content > .content').data('subdomain') + '/getPlaylists';
    filters = JSON.stringify(filters);
    $('.spinner').show();
    $('.masonry').html('');
    $.ajax($('body').data('ajaxbaseurl') + target, {
      method: 'get',
      dataType: 'jsonp',
      data: {
        filters: filters,
        page: page
      },
      success: function(xhr) {
        // console.log(xhr);
        if(page > 1) {
          $('.playlist-pagination button.prev').show();
        } else {
          $('.playlist-pagination button.prev').hide();
        }
        if(page * 24 < xhr.recordsTotal) {
          $('.playlist-pagination button.next').show();
        } else {
          $('.playlist-pagination button.next').hide();
        }
        $('.spinner').hide();
        var source = $("#catalog-playlist-item-template").html();
        var template = Handlebars.compile(source);
        for(var i = 0; i < xhr.data.length; i++) {
          $('.masonry').append(template(xhr.data[i]));
        }
        $('.masonry .header-image h3').dotdotdot({
          height: 120
        });
        if(xhr.data.length == 0) {
          $('.masonry').append('<p>No playlists match your search.</p>');
        }
      }
    })
  }
};

module.exports = outsideController;