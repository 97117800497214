/************************************
            APP.JS CORE
************************************/

$(document).ready(function(){
  // console.log('Yeay, it works!');
  // global toastr setup
  var router = require('./router/router');
  router.init();
});
