module.exports = {
  init: function() {
    if($("[data-body='body-catalog']").length > 0) {
      $('body').on('change', '.dataTable input[type="checkbox"]', function(e) {
        var $this = $(this);
        $.ajax('/catalog/updateCatalogItem', {
          method: 'post',
          data: {
            catalog_id: $(this).data('catalog'),
            item_id: $(this).data('item'),
            checked_flag: $(this).is(':checked') ? 1 : 0,
            item_type: $(this).data('type')
          },
          success: function(data) {
            $this.closest('.tab-pane').find('.update-filter').trigger('click');
          }
        })
      });
    }
    $('body').on('change', '#check-all', function(e) {
      let updatedCheckboxes = false;
      if($(this).is(':checked')) {
        // console.log('checked!');
        updatedCheckboxes = $('.bulk-checkbox:not(:checked)');
        $('.bulk-checkbox:not(:checked)').prop('checked', true);;
      } else {
        // console.log('not checked!');
        updatedCheckboxes = $('.bulk-checkbox:checked');
        $('.bulk-checkbox:checked').prop('checked', false);;
      }
      for(let i = 0; i < updatedCheckboxes.length; i++) {
        $(updatedCheckboxes[i]).trigger('change');
      }
    });
    $('body').on('change', '.bulk-checkbox', function(e) {
      if($('.bulk-actions').length > 0) {
        if($('.bulk-checkbox:checked').length > 0) {
          $('.bulk-actions button').removeAttr('disabled');
        } else {
          $('.bulk-actions button').attr('disabled', true);
        }
      }
    });
    $('body').on('click', '.delete-tracks', function(e) {
      let $items = $('.bulk-checkbox:checked:not(#check-all)');
      if($items.length > 0) {
        if(confirm('Are you sure you\'d like to delete ' + $items.length +' track(s)?')) {
          let tracks = [];
          for(let i = 0; i < $items.length; i++) {
            tracks.push($($items[i]).data('track'));
          }
          $.ajax('/track/deleteBulk', {
            method: 'post',
            data: {
              tracks: JSON.stringify(tracks)
            },
            success: function(xhr) {
              $('#check-all').removeAttr('disabled');
              $('.update-filter').trigger('click');
            }
          })
        }
      }
    });
    $('body').on('click', '.show-internal-nested-tracks', function(e) {
      e.preventDefault();
      var trackID = $(this).data('track');
      if($(this).hasClass('open')) {
        $(this).removeClass('open');
      } else {
        $(this).addClass('open');
      }
      let table = $('.responsive-data-table').DataTable();
      var tr = $(this).closest('tr');
      var row = table.row( tr );
      var div = $('<div />');
      let format = function( rowData ) {
        $.ajax( {
            url: '/track/getNestedTracks/' + trackID,
            dataType: 'json',
            success: function ( xhr ) {
              var source = $("#internal-nested-tracks").html();
              var template = Handlebars.compile(source);
              div.html(template(xhr));
              
              // resize the rows
              $(div).find('table tr:first-child td').each(function(i, elt) {
                $(elt).width($($('.responsive-data-table tbody tr:first-child td')[i]).width() + 'px');
              });
              
              // enable the playlist adds
              $(div).find('.add-playlist-track').popover({ 
                html : true,
                placement: 'left',
                container: 'body',
                trigger: 'click focus',
                title: function() {
                  return 'Add to Playlist';
                },
                content: function() {
                  var playlists = $(this).parent().data('playlists');
                  var trackID = $(this).parent().data('track');
                  if(playlists.length > 0) {
                    var content = '<div class="form-group"><select name="playlist_id" class="form-control">';
                    for(var i = 0; i < playlists.length; i++) {
                      content += '<option value="' + playlists[i].id + '">'+playlists[i].text+'</option>';
                    }
                    content += '</select><input type="hidden" name="track_id" value="'+trackID+'"><br /><button class="btn btn-primary add-to-playlist-button">Add</button><button class="btn btn-default modal-edit" data-modal="addPlaylist" data-trackid="'+trackID+'">New Playlist</button><button class="btn btn-default popover-close">Close</button></div></div>';
                    return content;
                  } else {
                    return '<div class="form-group"><button class="btn btn-default modal-edit" data-modal="addPlaylist" data-track_id="'+trackID+'">New Playlist</button><button class="btn btn-default popover-close">Close</button></div>';
                  }
                  /*
                  var trackID = $(this).data('trackid');
                  $.ajax('/my-tracks/getPlaylistsWithoutTrack/' + trackID, {
                    success: function(xhr) {
                      console.log(xhr);
                      return '<div class="form-group"><select class="form-control"><option>--</option><option>Playlist 1</option><option>Playlist 2</option></select><input type="hidden" value="'+trackID+'"><button class="btn btn-default">Add</button></div>';
                    }
                  });
                  */
                  
                }
              });
            }
        } );
     
        return div;
      }
      
      if ( row.child.isShown() ) {
          row.child.hide();
          tr.removeClass('shown');
      } else {
          row.child( format(row.data()) ).show();
          tr.addClass('shown');
      }
    })
    $('body').on('keypress', '#filter-container input.form-control', function(e) {
      
      var keycode = (e.keyCode ? e.keyCode : e.which);
      if(keycode == '13'){
          e.preventDefault();
          setTimeout(function() {
            $('.update-filter').trigger('click');
          }, 200);
      }
    })
    $('body').on('click', '.add-track-modal', function(e) {
      e.preventDefault();
      window.jsFiles.addTrackModal.source = 'addTrackModal';
      $('#addTrackModal').modal('show');
    });
    $('body').on('click', '.upload-spreadsheet-modal', function(e) {
      e.preventDefault();
      window.jsFiles.uploadSpreadsheetModal.open();
    });
    $('body').on('click','.open-buttons', function() {
      if($(this).hasClass('closed')) {
        $(this).removeClass('closed');
        $('.button-container').slideDown(500);
      } else {
        $(this).addClass('closed');
        $('.button-container').slideUp(500);
      }
    });
    $('body').on('click', '.show-filters', function(e) {
      if($(this).hasClass('open')) {
        $(this).html('Show Filters &#9654;');
        $(this).removeClass('open');
        $(this).closest('.container-fluid').find('.dataTables_wrapper').removeClass('filter-open');
        $(this).closest('.container-fluid').find('.filter-form').removeClass('showing');
      } else {
        $(this).html('&#9664; Hide Filters');
        $(this).addClass('open');
        $(this).closest('.container-fluid').find('.dataTables_wrapper').addClass('filter-open');
        $(this).closest('.container-fluid').find('.filter-form').addClass('showing');
      }
    });
    $('body').on('click', '.update-filter', function(e) {
      e.preventDefault();
      var $tab = $(this).closest('.tab-pane');
      if ($tab.length == 0) {
        $tab = $(this).closest('.list-container');
      }
      $tab.find('.responsive-data-table').DataTable().draw();  
      if($tab.find('.filter-form').hasClass('showing')) {
        $(this).closest('.container-fluid').find('.show-filters').trigger('click');
      }
    });
    $('body').on('click', '.add-to-playlist-button', function(e) {
      e.preventDefault();
      $.ajax('/my-tracks/addTrackToPlaylist', {
        method: 'post',
        data: {
          playlist_id: $(this).closest('.form-group').find('[name="playlist_id"]').val(),
          track_id: $(this).closest('.form-group').find('[name="track_id"]').val()
        },
        success: function() {
          toastr.success('Track added!');
           $('i.add-playlist-track').popover('hide');
        }
      })
    });
    $('body').on('click', 'i.icon.favorite-track', function(e) {
      e.preventDefault();
      var $this = $(this);
      if($this.data('track')) {
        $.ajax($('body').data('ajaxbaseurl') +'/track/toggleTrackFavorite', {
          method: 'get',
          dataType: 'jsonp',
          data: {
            track_id: $this.data('track')
          },
          success: function(data) {
            try {
              if(data.status == 1) {
                $this.addClass('active');
              } else if(data.status == 0) {
                $this.removeClass('active');
              } else {
                document.location = $('body').data('ajaxbaseurl') + '/login?message=likes&redirect=' + document.location ;  
              }
            } catch(e) {
              document.location = $('body').data('ajaxbaseurl') + '/login?message=likes&redirect=' + document.location ;  
            }
          },
          error: function() {
            document.location = $('body').data('ajaxbaseurl') + '/login?message=likes&redirect=' + document.location ;  
          }
        });
      } else if($this.data('album')) {
        $.ajax('/album/toggleAlbumFavorite', {
          method: 'post',
          data: {
            album_id: $this.data('album')
          },
          success: function(data) {
            if(data.status == 1) {
              $this.addClass('active');
            } else {
              $this.removeClass('active');
            }
          }
        });
      } else if($this.data('playlist')) {
        $.ajax('/playlist/togglePlaylistFavorite', {
          method: 'post',
          data: {
            playlist_id: $this.data('playlist')
          },
          success: function(data) {
            if(data.status == 1) {
              $this.addClass('active');
            } else {
              $this.removeClass('active');
            }
          }
        });
      }
    });
    this.onload();    
  },
  onload: function() {
    // enable popovers
    $('[data-toggle="popover"]').popover();
    
    // fix dismissing them
    $('body').on('click', function (e) {
      //did not click a popover toggle or popover
      if (!$(e.target).hasClass('add-playlist-track')
        && $(e.target).parents('.popover').length === 0) { 
        $('i.add-playlist-track').popover('hide');
      }
    });
    $('body').on('click', '.popover-close', function(e) {
      e.preventDefault();
      $('i').popover('hide');
    } )
    
    if($("body.page-list").length > 0 || $(".responsive-data-table").length > 0) {
        var filters = [];
        
        if(!$.fn.dataTable.isDataTable('.responsive-data-table:not(.delay-init)')) {
          var $tabs = $('.responsive-data-table:not(.delay-init)').closest('.tab-pane');
          if ($tabs.length == 0) {
            $tabs = $('.responsive-data-table:not(.delay-init)').closest('.list-container');
          };
          for(var i = 0; i < $tabs.length; i++) {
            let $tab = $($tabs[i]);
            
            // console.log($tab.find('.filter-form').data('columns'));
            let postFilterLoad = function() {
              var target = $tab.find('.filter-form').data('target');
              if(target.indexOf(':unique_id') >= 0) {
                target = target.substring(0, target.indexOf(':unique_id')) + $('.form-container').data('uniqueid');
              }
              
              $.fn.dataTable.moment( 'MMM D, YYYY' );
              
              $tab.find('.responsive-data-table:not(.delay-init)').on( 'preXhr.dt', function(e, settings, data) {
                if($tab.find('.filter-form').length > 0) {
                  if($tab.find('.filter-form').data('filterkey')) {
                    let tempData = window.otis.filters[$tab.find('.filter-form').data('filterkey')].getFilters();
                    data.track_type_flag = tempData.track_type_flag;
                    data.filters = JSON.stringify(tempData);
                    window.otis.filters[$tab.find('.filter-form').data('filterkey')].update()
                  } else {
                    var fields = $tab.find('.filter-form input, .filter-form select');
                    for(var j = 0; j < fields.length; j++) {
                      if($(fields[j]).attr('name')) {
                        data[$(fields[j]).attr('name')] = $(fields[j]).val();
                      }
                    }
                  }
                }
              }).DataTable({
                "PaginationType": "full_numbers", 
                responsive: true,
                serverSide: true, 
                "searching": false,
                "lengthMenu": [[ 25, 50, 100, 250], [25, 50, 100, 250]],
                order: [[$tab.find('.filter-form').data('sortcol'), 'desc']],
                dom: '<"tbl-top clearfix"lfr>,t,<"tbl-footer clearfix"<"tbl-info pull-left"i><"tbl-pagin pull-right"p>>',
                ajax: target,
                language: {
                    "emptyTable":     "No data"
                },
                stateSave: true,
                stateSaveCallback: function(settings,data) {
                    // localStorage.setItem( 'DataTables_' + settings.sInstance, JSON.stringify(data) )
                    var urlParams = new URLSearchParams(window.location.search);
                    let state = urlParams.get('state');
                    let saveState = true;
                    // add the filters
                    if($tab.find('.filter-form').length > 0) {
                      if($tab.find('.filter-form').data('filterkey')) { 
                        let tempData = window.otis.filters[$tab.find('.filter-form').data('filterkey')].getFilters();
                    
                        let filterFields = {};
                        filterFields.track_type_flag = tempData.track_type_flag;
                        filterFields.filters = JSON.stringify(tempData);
                        data.filterFields = filterFields;
                      } else { 
                        var fields = $tab.find('.filter-form input, .filter-form select');
                        let filterFields = {};
                        for(var j = 0; j < fields.length; j++) {
                          if($(fields[j]).attr('name')) {
                            filterFields[$(fields[j]).attr('name')] = $(fields[j]).val();
                          }
                        }
                        data.filterFields = filterFields;
                      }
                    }
                    if(state) {
                      let stateJson = JSON.parse(state), newData = data;
                      delete stateJson.time;
                      let time = newData.time;
                      delete newData.time;
                      if(JSON.stringify(newData) == JSON.stringify(stateJson)) {
                        saveState = false;
                      }
                      data.time = time;
                      // console.log('should I save?', saveState, settings, newData, stateJson);
                    }
                    
                    // only push state if we're looking at this tab
                    if($('#tab-selector').length == 0 || $(settings.nTable).hasClass($('#tab-selector').val() + '-data-table')) {
                      if(saveState && document.location.search.indexOf('state=') !== -1) {
                        // console.log('pushing state', data.start);
                        history.pushState({}, $('title').text(), document.location.pathname + '?state=' + encodeURIComponent(JSON.stringify(data)) + document.location.hash);   
                      } else {
                        history.replaceState({}, $('title').text(), document.location.pathname + '?state=' +encodeURIComponent(JSON.stringify(data)) + document.location.hash);   
                      }
                    }
                },
                stateLoadCallback: function(settings) {
                  var urlParams = new URLSearchParams(window.location.search);
                  let state = urlParams.get('state');
                  if(state) {
                    let stateJson = JSON.parse(state);
                    // console.log('loading state. start index:', stateJson.start, settings)
                    
                    if(stateJson.filterFields) {
                      for(let name in stateJson.filterFields) {
                        if($tab.find('.filter-form').data('filterkey')) {
                          if(name == 'filters') {
                            let filters = JSON.parse(stateJson.filterFields[name]);
                            for(let filterName in filters) {
                              if(!filters[filterName]) {
                                
                              } else if(Array.isArray(filters[filterName])) {
                                $tab.find('.filter-form .option-container[data-field="'+filterName+'"] input').prop('checked', false);
                                $tab.find('.filter-form .option-container[data-field="'+filterName+'"]').find('[value="' + filters[filterName].join('"], [value="') + '"]').prop("checked", true);
                              } else {
                                $tab.find('.filter-form .form-control[name="'+filterName+'"]').val(filters[filterName]);
                              }  
                            }
                          } else {
                            if(Array.isArray(stateJson.filterFields[name])) {
                              $tab.find('.filter-form .option-container[data-field="'+name+'"] input').prop('checked', false);
                              $tab.find('.filter-form .option-container[data-field="'+name+'"]').find('[value=' + stateJson.filterFields[name].join('], [value=') + ']').prop("checked", true);
                            } else {
                              $tab.find('.filter-form .form-control[name="'+name+'"]').val(stateJson.filterFields[name]);
                            }
                          }
                        } else {
                          $tab.find('.filter-form .form-control[name="'+name+'"]').val(stateJson.filterFields[name]);
                        }
                      }
                    }
                    
                    return stateJson;
                  } else {
                    //console.log('no state to load');
                    return false;
                  }
                  
                },
                columns:  $tab.find('.filter-form').data('columns'),
                createdRow: function ( row, data, index ) {
                   $(row).addClass('playlist-track')
                }, 
                initComplete: function(settings, json) {
                  
                }
              }).on('draw.dt', function() {
                window.jsFiles.player.checkPlayingTrack();
                // add playlist track popovers
                $('body.page-list .add-playlist-track').popover({ 
                  html : true,
                  placement: 'left',
                  container: 'body',
                  trigger: 'click focus',
                  title: function() {
                    return 'Add to Playlist';
                  },
                  content: function() {
                    var playlists = $(this).parent().data('playlists');
                    var trackID = $(this).parent().data('track');
                    if(playlists.length > 0) {
                      var content = '<div class="form-group"><select name="playlist_id" class="form-control">';
                      for(var i = 0; i < playlists.length; i++) {
                        content += '<option value="' + playlists[i].id + '">'+playlists[i].text+'</option>';
                      }
                      content += '</select><input type="hidden" name="track_id" value="'+trackID+'"><br /><button class="btn btn-primary add-to-playlist-button">Add</button><button class="btn btn-default modal-edit" data-modal="addPlaylist" data-trackid="'+trackID+'">New Playlist</button><button class="btn btn-default popover-close">Close</button></div></div>';
                      return content;
                    } else {
                      return '<div class="form-group"><button class="btn btn-default modal-edit" data-modal="addPlaylist" data-track_id="'+trackID+'">New Playlist</button><button class="btn btn-default popover-close">Close</button></div>';  
                    }
                    /*
                    var trackID = $(this).data('trackid');
                    $.ajax('/my-tracks/getPlaylistsWithoutTrack/' + trackID, {
                      success: function(xhr) {
                        console.log(xhr);
                        return '<div class="form-group"><select class="form-control"><option>--</option><option>Playlist 1</option><option>Playlist 2</option></select><input type="hidden" value="'+trackID+'"><button class="btn btn-default">Add</button></div>';
                      }
                    });
                    */
                    
                  }
                });
                
                $('body.page-list .add-playlist-track').on('show.bs.popover', function () {
                  $('i.add-playlist-track').popover('hide');
                });
              });
            }
            if($tab.find('.filter-form').data('filterkey')) {
              window.otis.filters[$tab.find('.filter-form').data('filterkey')].init(undefined, postFilterLoad); 
            } else {
              postFilterLoad();
            }
            
          }
        }
        
        
      }
      
    
  }
}
/*
{
      "bProcessing": true,
      "sAjaxSource": "js/datatables/datatable.json",
      "sDom": "<'row'<'col-sm-6'l><'col-sm-6'f>r>t<'row'<'col-sm-6'i><'col-sm-6'p>>",
      "sPaginationType": "full_numbers",
      "aoColumns": [
        { "mData": "engine" },
        { "mData": "browser" },
        { "mData": "platform" },
        { "mData": "version" },
        { "mData": "grade" }
      ]
    }
    */