module.exports = {
  objectID: false,
  objectType: false,
  folderID: false,
  source: 'addTrackModal',
  init: function() {
    $('#addTrackModal-upload, #addTrackModal-details').on('click', function(e) {
      var which = $(this).attr('id');
      e.preventDefault();
      var validator = $('#addTrackModal-form').validate();
      if(validator.form()) {
        var data = new FormData();
        data.append('source', window.jsFiles.addTrackModal.source);
        if($('#add-to-catalog-check').length > 0) {
          if($('#add-to-catalog-check')[0].checked) {
            data.append('add_to_catalog', 'Y');
          } else {
            data.append('add_to_catalog', 'N');
          }
        }
        if(window.jsFiles.addTrackModal.source == 'addNestedTrack' || window.jsFiles.addTrackModal.source == 'updateNestedTrack') {
          data.append('track_id', window.jsFiles.addTrackModal.objectID);
        }
        $.each($('#addTrackModal-tracks')[0].files, function(i, file) {
            data.append('tracks', file);
        });
        var progress = function(e) {
          if(e.lengthComputable){
              var max = e.total;
              var current = e.loaded;
      
              var Percentage = (current * 100)/max;
              $('#addTrackModal .progress-bar').css('width', Percentage + '%');
      
              if(Percentage >= 100)
              {
                 // process completed  
              }
          } 
        }
        jQuery.ajax({
          url: '/track/upload',
          data: data,
          cache: false,
          contentType: false,
          processData: false,
          type: 'POST',
          xhr: function() {
            var myXhr = $.ajaxSettings.xhr();
            if(myXhr.upload){
                myXhr.upload.addEventListener('progress',progress, false);
            }
            return myXhr;
          },
          success: function(data){
            // console.log(data);
            if(window.jsFiles.addTrackModal.source == 'addNestedTrack') {
              window.modals.updateNestedTrack.initVars({track_id: data.trackID});
              window.jsFiles.addTrackModal.objectID = false; 
              window.jsFiles.addTrackModal.objectType = false;
              window.jsFiles.addTrackModal.source = 'addTrackModal';
              $('#addTrackModal').modal('hide');
              
            } else if(window.jsFiles.addTrackModal.source == 'updateNestedTrack') {
              $('i.play-track[data-track="'+window.jsFiles.addTrackModal.objectID+'"').addClass('disabled');
              $('#addTrackModal').modal('hide');
              window.jsFiles.addTrackModal.objectID = false; 
              window.jsFiles.addTrackModal.objectType = false;
              window.jsFiles.addTrackModal.source = 'addTrackModal';
            } else { 
              if(window.jsFiles.addTrackModal.objectID) {
                window.jsFiles.updateTrackModal.objectID = window.jsFiles.addTrackModal.objectID;
                window.jsFiles.updateTrackModal.objectType = window.jsFiles.addTrackModal.objectType;
                window.jsFiles.addTrackModal.objectID = false; 
                window.jsFiles.addTrackModal.objectType = false;
                window.jsFiles.addTrackModal.source = 'addTrackModal';
                if(window.jsFiles.addTrackModal.folderID) {
                  window.jsFiles.updateTrackModal.folderID = window.jsFiles.addTrackModal.folderID;
                  window.jsFiles.addTrackModal.folderID = false;
                }
              }
              window.jsFiles.updateTrackModal.openModal(data.trackID);
            }
            $('#addTrackModal form')[0].reset();
            $('#addTrackModal .progress-bar').css('width',  '0%');
          }
        });
      }
    });
  }
}