var sharePlaylistModal = {
  updateItem: false,
  init: function() {
    $('#sharePlaylistModal-share').on('click', function(e) {
      var which = $(this).attr('id');
      e.preventDefault();
      var validator = $('#sharePlaylistModal-form').validate({
        highlight: function (element, errorClass, validClass) {
          var elem = $(element);
          elem.addClass(errorClass);
        },
    
        unhighlight: function (element, errorClass, validClass) {
          var elem = $(element);
          elem.removeClass(errorClass);
        }
      });
      if(validator.form()) {
        $.ajax({
          url: '/playlist/addShare/' + sharePlaylistModal.updateItem,
          data: {
            first_name: $('#sharePlaylistModal-firstName').val(),
            last_name: $('#sharePlaylistModal-lastName').val(),
            email: $('#sharePlaylistModal-email').val(),
            message: $('#sharePlaylistModal-message').val(),
            send_email_flag: $('#sharePlaylistModal-sendEmailFlag').val()
          },
          type: 'POST',
          success: function(data){
            if(data.error) {
              $('#sharePlaylistModal-error').html(data.error);
              $('#sharePlaylistModal-error').css('display', 'block');
            } else {
              $('#sharePlaylistModal-error').css('display', 'none');
              $('#sharePlaylistModal-form')[0].reset(); 
              // console.log(data);
              if(sharePlaylistModal.updateItem) {
                $.ajax({
                  url: '/playlist/getShares/' + sharePlaylistModal.updateItem,
                  success: function(data) {
                    if(data.shares.length == 0) {
                      $('#playlist-shares').html('<li>This playlist has not yet been shared.</li>');
                    } else {
                      var source = $("#playlist-share-template").html();
                      var template = Handlebars.compile(source);
                      $('#playlist-shares').html(template(data));
                    }
                    // window.location = '/playlist/' + sharePlaylistModal.updateItem + '#tab3';
                    sharePlaylistModal.updateItem = false;
                  }
                });
              }
              $('#sharePlaylistModal').modal('hide');
            }
          }
        });
      }
    });
  
  }
}

module.exports = sharePlaylistModal;