var trackMessageModal = {
  open: function(trackID, trackName, artistName) {
    $("#trackMessageModal-track_id").val(trackID);
    $("#trackMessageModal-track_name").html(trackName);
    $("#trackMessageModal-artist_name").html(artistName);
    $('#trackMessageModal').modal('show');
  },
  init: function() {
    $('#trackMessageModal-send').on('click', function(e) {
      var which = $(this).attr('id');
      e.preventDefault();
        var validator = $('#trackMessageModal-form').validate({});
      if(validator.form()) {
        $.ajax({
          url: $('body').data('ajaxbaseurl') + '/sendTrackMessage',
          data: {
            name: $('#trackMessageModal-name').val(),
            email: $('#trackMessageModal-email').val(), 
            message: $('#trackMessageModal-message').val(), 
            track_id: $('#trackMessageModal-track_id').val(),
          },
          type: 'get',
          dataType: 'jsonp',
          success: function(data){
            if(data.error) {
              $('#trackMessageModal-error').html(data.error);
              $('#trackMessageModal-error').css('display', 'block');
            } else {
              $('#trackMessageModal-error').css('display', 'none');
              $('#trackMessageModal-message').val(''); 
              $("#trackMessageModal-track_id").val('');
              $("#trackMessageModal-track_name").html('');
              $("#trackMessageModal-artist_name").html('');
              // console.log(data);
              $('#trackMessageModal').modal('hide');
            }
          }
        });
      }
    });
  }
}

module.exports = trackMessageModal;