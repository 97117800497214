var addVideoModal = {
  playlistID: false,
  folderID: false,
  openModal: function(playlistID, folderID) {
    addVideoModal.playlistID = playlistID;
    addVideoModal.folderID = folderID;
    $('#addVideoModal').modal('show');
  },
  init: function() {
    $('#addVideoModal-upload').on('click', function(e) {
      var which = $(this).attr('id');
      e.preventDefault();
      var validator = $('#addVideoModal-form').validate();
      if(validator.form()) {
        var data = new FormData();
        data.append('playlist_id', addVideoModal.playlistID);
        if(addVideoModal.folderID) {
          data.append('folder_id', addVideoModal.folderID);
        }
        data.append('source', 'addVideoModal');
        $.each($('#addVideoModal-tracks')[0].files, function(i, file) {
            data.append('tracks', file);
        });
        
        var progress = function(e) {
          if(e.lengthComputable){
              var max = e.total;
              var current = e.loaded;
      
              var Percentage = (current * 100)/max;
              $('#addVideoModal .progress-bar').css('width', Percentage + '%');
      
              if(Percentage >= 100)
              {
                 $('.processing').show();
              }
          } 
        }
        jQuery.ajax({
          url: '/track/upload',
          data: data,
          cache: false,
          contentType: false,
          processData: false,
          timeout: 0,
          type: 'POST',
          xhr: function() {
            var myXhr = $.ajaxSettings.xhr();
            if(myXhr.upload){
                myXhr.upload.addEventListener('progress',progress, false);
            }
            return myXhr;
          },
          success: function(data){
            $('#addVideoModal form')[0].reset();
            $('#addVideoModal .progress-bar').css('width',  '0%');
            $('.processing').hide();
            addVideoModal.playlistID = false;
            addVideoModal.folderID = false;
            $('#updateVideoModal input[name="video_id"]').val(data.videoID);
            $('#updateVideoModal input[name="video_name"]').val(data.name);
            window.openModal('#updateVideoModal');
            // window.jsFiles.updateVideoModal.openModal(data.videoID);
          },
          error: function(data) {
            console.log(data);
          }
        });
      }
    });
  }
}
module.exports = addVideoModal;