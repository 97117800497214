// $(document).ready(function(){
var homeController = {
  init: function() {
    $('body').on('click','.browser-back-link', function(e) {
      e.preventDefault();
      history.go(-1);
    })
    if(document.location.hash) {
      let hash = document.location.hash;
      console.log(hash);
      if(hash.substring(1,4) == 'tab'){
        $('a[href="'+hash+'"]').trigger('click');
      } else if(hash.substring(1, 6) == 'login') {
        const urlParams = new URLSearchParams(window.location.search);
        const messageType = urlParams.get('message');
        var message = false;
        if(messageType) {
          switch(messageType) {
            case 'download-access':
              message = 'You must login/create a free account for download access.';
              break;
            case 'likes':
              message = 'You must be logged in or create a free account to favorite tracks.';
              break;
          }
        }
        if(message) {
          $('#loginModal-warning').html(message + '<br /><a class="browser-back-link" href="#">Go back</a>');
          $('#loginModal-warning').show();
        }
        $('#loginModal').modal('show');
      } else if(hash.substring(1, 7) == 'signup') {
        $('#signupModal').modal('show');
      } 
    }
    $('#loginModal-login').on('click', function(e) {
      e.preventDefault();
      $.ajax({
        method: 'post',
        url: '/login',
        data: {
          email: $('#loginModal-email').val(), 
          password: $('#loginModal-password').val(),
          redirect: $('#loginModal-redirect').val()
        },
        success: function(xhr) {
          if(xhr.errors) {
            let temp = '';
            if(xhr.errors.length == 1){
              temp = xhr.errors[0]; 
            } else {
              temp = '<ul>';
              for(var i =0; i < xhr.errors.length; i++) {
                temp += '<li>'+xhr.errors[i] + '</li>';
              }
              temp += '</ul>';
            }
            $('#loginModal-warning').hide();
            $('#loginModal-error').html(temp);
            $('#loginModal-error').show();
          } else {
            $('#loginModal-warning').hide();
            $('#loginModal-email').val('');
            $('#loginModal-password').val('');
            $('#loginModal-error').hide();
            document.location = xhr.redirect;
          }
        }
      })
    });
    $('body').on('click', '.open-modal', function(e) {
      e.preventDefault();
      $('.modal').modal('hide');
      if($(this).data('modal')) {
        let modal = $(this).data('modal');
        setTimeout(function() {
          $(modal).modal('show');
        }, 500);
      }
    });
    this.onload();
  },
  onload: function() {
    if($(".page-dashboard").length > 0) {
      // playlist carousel 
      $('.playlist-carousel-container .controls .prev').on('click', function(e) {
        e.preventDefault();
        $('#playlist-carousel').carousel('prev')
      });
      $('.playlist-carousel-container .controls .next').on('click', function(e) {
        e.preventDefault();
        $('#playlist-carousel').carousel('next')
      });
      try {
        window.initDropzone('#dashboard-dropzone', {source: 'bulkUpload'}, function(dz, file) {
          toastr.success('Track uploaded!');
          homeController.updatePage();
          dz.removeFile(file);
        }, 'audio/*');
      } catch(e) {
        console.log('dzerror', e);
      }
      homeController.updatePage();
    }
    if($('.page-home').length > 0) {
      // enable swipe on carousels
      $('.carousel').bcSwipe({ threshold: 50 });
    }
  },
  updatePage: function() {
    $.ajax('/dashboard/metadataData', {
      complete: function(xhr) {
        if(xhr.responseJSON.totalPoints > 0) {
          $('.metadata-progress').html( Math.round((xhr.responseJSON.earnedPoints / xhr.responseJSON.totalPoints ) * 1000 ) / 10 +'%' /* + '<div class="count' + (((xhr.responseJSON.earnedPoints / xhr.responseJSON.totalPoints ) * 100 ) < 50 ? ' outer' : "")+'">' + xhr.responseJSON.earnedPoints + '/' + xhr.responseJSON.totalPoints + '<div>' */ );
          $('.metadata-progress').css('width', ((xhr.responseJSON.earnedPoints / xhr.responseJSON.totalPoints ) * 100 ) +'%');
          $('.metadata-box .item-container').html('');
          for(var i =0; i < xhr.responseJSON.nextTracks.length; i++) {
            $('.metadata-box .item-container').append(`
            <div class="metadata-item modal-edit" data-modal="dailyMetadata" data-uniqueid="` + xhr.responseJSON.nextTracks[i].trackID +`" >
                <div class="circle">
                  <span class="top">ADD</span>
                  <span class="plus">+</span>
                  <span class="bottom">META</span>
                </div>
                <div class="track-deets">
                  <strong>` + xhr.responseJSON.nextTracks[i].name +`</strong><br />
                  ` + xhr.responseJSON.nextTracks[i].artistName +`
                </div>
              </div>
            `)  
          }
        } else {
          $('.metadata-box').parent().hide();
        }
      }
    })
    $.ajax('/dashboard/data', {
      complete: function(xhr) {
        var counts = ['trackCount','albumCount'];
        var options = {
          useEasing : true, 
          useGrouping : true, 
          separator : ',', 
          decimal : '.'
        };
        for(var i = 0; i < counts.length; i++) {
          var demo = new CountUp("home-" + counts[i], parseInt($('#home-' + counts[i]).text().replace(/,/g, "")), xhr.responseJSON[counts[i]], 0, 1.5, options);
          demo.start();
        }
        $('#weekly-top-tracks').html('');
        let tracks = xhr.responseJSON.tracks;
        if(tracks.length == 0) {
          $('#weekly-top-tracks').html('<div class="col-12">You do not have any tracks.</div>');
        } else {
          var maxListens = 0;
          for(var i = 0; i < tracks.length; i++) {
            if(i == 0) maxListens = tracks[i].weeklyListens;
            $('#weekly-top-tracks').append(`<div class="col-6">
                <div class="track-deets">
                  <a href="`+tracks[i].url+`"><strong>`+tracks[i].trackName+`</strong></a><br />
                  `+tracks[i].artistName+`
                </div>
              </div>
              <div class="col-6">
                <div class="track-listen-bar" style="width: ` + ((tracks[i].weeklyListens / maxListens) * 100 ) +`%">
                  `+tracks[i].weeklyListens.toLocaleString('en-US' )+`
                </div>
              </div>`);
          } 
        }
        let playlists = xhr.responseJSON.playlists;
        $('table.top-playlists tbody').html('');
        if(playlists.length == 0) {
          $('table.top-playlists tbody').html('<tr><td colspan="3">You do not have any playlists.</td></tr>');
        } else {
          for(var i = 0; i < playlists.length; i++) {
            $('table.top-playlists tbody').append('<tr><td><a href="'+playlists[i].url+'">'+playlists[i].playlistName+'</a></td><td>'+playlists[i].dailyListens+' / '+playlists[i].weeklyListens+'</td><td>'+(playlists[i].recentFlag == 1 ? '<span class="label label-info label-mini">New</span>' : '&nbsp;')+'</td></tr>');
          } 
          $('table.top-playlists tbody').append('<tr><td colspan="3"><a href="/playlists">View all</a></td></tr>');
        }
        
        // init the chart
        homeController.initChart(xhr.responseJSON.monthlyStats.background, xhr.responseJSON.monthlyStats.data, xhr.responseJSON.monthlyStats.labels);
        
        // init the listeners
        $('.top-listeners').html('');
        for(var i = 0 ; i < xhr.responseJSON.topListeners.length; i++) {
          $('.top-listeners').append(`
            <div class="col-4">
              <img src="/image/avatar/`+xhr.responseJSON.topListeners[i].uniqueID +`">
              <span class="person">
                `+xhr.responseJSON.topListeners[i].name +`.<br />`+xhr.responseJSON.topListeners[i].listens +` listens
              </span>
            </div>
            
          `)
        }
        
        //init the playlists
        $('#playlist-carousel .carousel-inner').html('');
        var newHTML = '<div class="carousel-item active">';
        for(var i = 0; i < xhr.responseJSON.playlists.length; i++) {
          if(i != 0 && i % 3 == 0) {
            newHTML += '</div><div class="carousel-item">';
          }
          newHTML += `
            <div class="playlist-item" style="` +(xhr.responseJSON.playlists[i].hasOwnProperty('coverImageID') && xhr.responseJSON.playlists[i].coverImageID ? 'background-image: url(/image/view/'+xhr.responseJSON.playlists[i].coverImageID+')' : 'background-image: url(/assets/img/hero.playlist.raw.jpg);')+`">
              <div class="overlay"></div>
              <div class="caption">
                <h5><a href="`+xhr.responseJSON.playlists[i].url+`">`+xhr.responseJSON.playlists[i].playlistName+`</a></h5>
                <p>`+xhr.responseJSON.playlists[i].listens+` listen`+ (xhr.responseJSON.playlists[i].listens != 1 ? 's' : '')+`</p>  
              </div>
            </div>
          `;
        }
        newHTML += '</div>';
        $('#playlist-carousel .carousel-inner').html(newHTML);
      }
    });
  }, 
  initChart: function(backgroundData, chartData, labels) {
    var myChart = echarts.init(document.getElementById('monthly-track-chart'));
    var showLabels = false, showVerticalLabels = false;
    // see if data exists
    for(var i = 0; i < chartData.length; i++) {
      if(chartData[i] > 0) {
        showLabels = true;
      }
      if(chartData[i] > 3) {
        showVerticalLabels = true;
      } 
    }
    
    var option = {
      backgroundColor: '#fff',
      legend: {
        // show: false,
      },
      grid: {
        left: '0%',
        right: '0%',
        bottom: '2%',
        top: '5%',
        containLabel: true
      },
      tooltip: {
          show: "false",
          trigger: 'item',
          backgroundColor: 'rgba(0,0,0,0.7)', // 背景
          padding: [8, 10], //内边距
          extraCssText: 'box-shadow: 0 0 3px rgba(255, 255, 255, 0.4);', //添加阴影
          formatter: function(params) {
              if (params.seriesIndex == "3" || params.seriesIndex == "4" || params.seriesIndex == "5") {
                  return params.name + '<br>' + params.seriesName + ' ： 第 ' + params.value + ' 名';
              }
          }
      },
      yAxis: {
          type: 'value',
          axisTick: {
              show: showVerticalLabels,
              lineStyle: {
                  color: '#212529',
              }
          },
          axisLine: {
              show: false,
              lineStyle: {
                  color: '#363e83',
              }
          },
          splitLine: {
              show: false,
              lineStyle: {
                  color: '#363e83 ',
              }
          },
          axisLabel: {
            show: showVerticalLabels,
            textStyle: {
                color: '#212529',
                fontWeight: 'normal',
                fontSize: '10',
            },
          },
      },
      xAxis: [{
              type: 'category',
              axisTick: {
                  show: false
              },
              axisLine: {
                  show: false,
                  lineStyle: {
                      color: '#363e83',
                  }
              },
              axisLabel: {
                show: false,
              },
          }, {
              type: 'category',
              data: labels, 
              axisLine: {
                  show: false
              },
              axisTick: {
                  show: false
              },
              axisLabel: {
                  show: showLabels, 
                  inside: true,
                  // position: 'insideBottom',
                  margin: 5,
                  verticalAlign: 'middle',
                  align: 'left', 
                  rotate: 90,
                  
                  textStyle: {
                    color: '#212529',
                    fontWeight: 'normal',
                    fontSize: '10',
                  },
              },
              splitArea: {
                  show: false
              },
              splitLine: {
                  show: false
              },
             z: 10, 
             position: 'bottom'
          },
  
      ],
      series: [
        {
          type: 'bar',
          xAxisIndex: 1,
          z: 1,
          itemStyle: {
            normal: {
              color: '#F7F9FA',
              borderWidth: 0,
              shadowBlur: {
                shadowColor: 'rgba(255,255,255,0.31)',
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowOffsetY: 2,
              },
            }
          },
          barWidth: '45%',
          data: backgroundData
        }, 
        {
          type: 'bar',
          itemStyle: {
            normal: {
              show: true,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: '#f8525b'
              }, {
                offset: 1,
                color: '#FD81E1'
                  
              }]),
              barBorderRadius: 50,
              borderWidth: 0,
            }
          },
          z: 2,
          barWidth: '45%',
          data: chartData
        }
      ]
    };
    myChart.setOption(option);
    window.onresize = function() {
      myChart.resize();
    };
  }
};

module.exports = homeController;