var calendarController = {
  init: function() {
    
    window.calendar = false;
    /*          
    window.calendar = new FullCalendar.Calendar(calendarEl, {
      plugins: [ 'interaction', 'dayGrid', 'timeGrid', 'list' ],
      defaultView: 'dayGridMonth',
      defaultDate: new Date(),
      header: {
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
      },
      events: '/calendar/list',
      eventClick: function(info) { 
        window.modals.updateTask.initVars({unique_id: info.event.extendedProps.uniqueID, child_record_needed: info.event.extendedProps.childRecordNeeded, event_date_start: info.event.extendedProps.startDate, event_date_end: info.event.extendedProps.endDate});
        // console.log(info.event.extendedProps);
      }
    });
    */
    calendarController.onload();  
      
  },
  onload: function() {
    if($('.page-calendar').length > 0) {
      if(!window.calendar) {
        var calendarEl = document.getElementById('calendar');
    
        window.calendar = new FullCalendar.Calendar(calendarEl, {
          plugins: [ 'interaction', 'dayGrid', 'timeGrid', 'list' ],
          defaultView: 'dayGridMonth',
          defaultDate: new Date(),
          header: {
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
          },
          events: '/calendar/list',
          eventClick: function(info) { 
            window.modals.updateTask.initVars({unique_id: info.event.extendedProps.uniqueID, child_record_needed: info.event.extendedProps.childRecordNeeded, event_date_start: info.event.extendedProps.startDate, event_date_end: info.event.extendedProps.endDate});
            // console.log(info.event.extendedProps);
          }
        });  
      }
      
      
      // some handlers
      $('#addTaskModal-all_day_flag').on('change', function() {
        if($('#addTaskModal-all_day_flag').val() == '0') {
          $('#addTaskModal-event_start_date_only').closest('.form-group').hide();
          $('#addTaskModal-event_start_date').closest('.form-group').show();
          $('#addTaskModal-event_end_date').closest('.form-group').show();
        } else { 
          $('#addTaskModal-event_start_date_only').closest('.form-group').show();
          $('#addTaskModal-event_start_date').closest('.form-group').hide();
          $('#addTaskModal-event_end_date').closest('.form-group').hide();
        } 
      });
      $('#addTaskModal-recurrence_flag').on('change', function() {
        if($('#addTaskModal-recurrence_flag').val() == '0') {
          $('#addTaskModal-recurrence_end_date').closest('.form-group').hide();
        } else { 
          $('#addTaskModal-recurrence_end_date').closest('.form-group').show();
        } 
      });
      $('#updateTaskModal-all_day_flag').on('change', function() {
        if($('#updateTaskModal-all_day_flag').val() == '0') {
          $('#addTaskModal-event_start_date_only').closest('.form-group').hide();
          $('#updateTaskModal-event_start_date').closest('.form-group').show();
          $('#updateTaskModal-event_end_date').closest('.form-group').show();
        } else {
          $('#updateTaskModal-event_start_date_only').closest('.form-group').show();
          $('#updateTaskModal-event_start_date').closest('.form-group').hide();
          $('#updateTaskModal-event_end_date').closest('.form-group').hide();
        } 
      });
      $('#updateTaskModal-recurrence_flag').on('change', function() {
        if($('#updateTaskModal-recurrence_flag').val() == '0') {
          $('#updateTaskModal-recurrence_end_date').closest('.form-group').hide();
        } else { 
          $('#updateTaskModal-recurrence_end_date').closest('.form-group').show();
        } 
      });
      
      $('body').on('click', '.edit-recurring-event', function() {
        window.modals.updateTask.initVars({unique_id: $('#updateTaskModal-parent_task_id').val(), child_record_needed: false});
      });
       $('body').on('click', '.delete-event', function() {
        if(confirm('Are you sure you want to delete this task/event?')) {
          $.ajax('/calendar/delete', {
            method: 'POST',
            data: {
              task_id: $('#updateTaskModal-task_id').val()
            },
            success: function(xhr) {
              window.calendar.refetchEvents();
              window.calendar.updateSize();
              $('.modal').modal('hide');
            }
          });
        }
      });
      
      $('body').on('change', '#addTaskModal-event_start_date_only', function() {
        let temp = new Date($('#addTaskModal-event_start_date_only').val() + 'T00:00');
        let temp2 = new Date(temp.getTime() - temp.getTimezoneOffset() * 60000);
        $('#addTaskModal-event_start_date').val(temp2.toISOString().substring(0,temp2.toISOString().length-1));
        temp = new Date(temp.getTime() + (60*60*1000) - temp.getTimezoneOffset() * 60000);
        console.log(temp);
        console.log($('#addTaskModal-event_start_date_only').val());
        console.log(temp.toISOString().substring(0,temp.toISOString().length-1));
        $('#addTaskModal-event_end_date').val(temp.toISOString().substring(0,temp.toISOString().length-1));
      });
      $('body').on('change', '#addTaskModal-event_start_date', function() {
        let temp = new Date($('#addTaskModal-event_start_date').val());
        $('#addTaskModal-event_start_date_only').val(temp.toISOString().substring(0,10));
        temp = new Date(temp.getTime() + (60*60*1000) - temp.getTimezoneOffset() * 60000);
        console.log($('#addTaskModal-event_start_date').val());
        console.log(temp.toISOString().substring(0,temp.toISOString().length-1));
        $('#addTaskModal-event_end_date').val(temp.toISOString().substring(0,temp.toISOString().length-1));
      });
       $('body').on('change', '#updateTaskModal-event_start_date_only', function() {
        let temp = new Date($('#updateTaskModal-event_start_date_only').val() + 'T00:00');
        let temp2 = new Date(temp.getTime() - temp.getTimezoneOffset() * 60000);
        $('#updateTaskModal-event_start_date').val(temp2.toISOString().substring(0,temp2.toISOString().length-1));
        temp = new Date(temp.getTime() + (60*60*1000) - temp.getTimezoneOffset() * 60000);
        console.log(temp);
        console.log($('#updateTaskModal-event_start_date_only').val());
        console.log(temp.toISOString().substring(0,temp.toISOString().length-1));
        $('#updateTaskModal-event_end_date').val(temp.toISOString().substring(0,temp.toISOString().length-1));
      });
      $('body').on('change', '#updateTaskModal-event_start_date', function() {
        let temp = new Date($('#updateTaskModal-event_start_date').val());
        $('#updateTaskModal-event_start_date_only').val(temp.toISOString().substring(0,10));
        temp = new Date(temp.getTime() + (60*60*1000) - temp.getTimezoneOffset() * 60000);
        console.log($('#updateTaskModal-event_start_date').val());
        console.log(temp.toISOString().substring(0,temp.toISOString().length-1));
        $('#updateTaskModal-event_end_date').val(temp.toISOString().substring(0,temp.toISOString().length-1));
      });
      
      window.calendar.render();
    }
  }
}
module.exports = calendarController;