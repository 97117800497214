var uploadImageModal = {
  objID: false,
  objType: false,
  imageType: false,
  updateTarget: false,
  uploadedImageUrl: false,
  objectImageType: false,
  imageID: false,
  sizes: {},
  cropper: false,
  open: function() {
    uploadImageModal.objID = false;  
    uploadImageModal.objType = false;
    uploadImageModal.imageType = false;
    uploadImageModal.updateTarget = false,
    uploadImageModal.uploadedImageUrl = false,
    uploadImageModal.objectImageType = false,
    uploadImageModal.imageID = false;
    uploadImageModal.sizes = {};
    if(uploadImageModal.cropper) {
      uploadImageModal.cropper.destroy();
    }
    $('#addImageModal #imageToBeCropped').attr('src', true);
    $('#addImageModal-file').val('');
    $('#addImageModal').modal('show');
  },
  init: function() {
    // show modal
    $('body').on('click', '.update-avatar', function(e) {
        e.preventDefault();
        if($(this).data('updatetarget')) {
          uploadImageModal.updateTarget = $(this).data('updatetarget');
        }
        
        $.ajax('/image/avatars/list/', {
          complete: function(xhr, responseText) {
            $('#updateAvatarModal-select').html('');
            var avatars = xhr.responseJSON;
            for(var i = 0; i < avatars.length; i++) {
              $('#updateAvatarModal-select').append('<option data-img-src="'+avatars[i].url+'" value="'+avatars[i].url+'"></option>');  
            }
            $("#updateAvatarModal-select").imagepicker();
            $('#updateAvatarModal').modal('show');
          }
        });  
    });
    $('#updateAvatarModal-update').on('click', function(e) {
      $.ajax('/account/profile', {
        method: 'post',
        data: {
          field: 'avatar',
          value: $('#updateAvatarModal-select').val()
        },
        success: function(xhr, textResponse) {
          if(xhr.error) {
            toastr.error(xhr.error);
          } else {
            toastr.success('Saved!');
            if(uploadImageModal.updateTarget) {
              setTimeout( function() {
                $(uploadImageModal.updateTarget).attr('src', $('#updateAvatarModal-select').val());
              }, 1000);
            }
            $('#updateAvatarModal').modal('hide');
          }
        }
      });  
    });
    $('body').on('click', '.update-image', function(e) {
        e.preventDefault();
        uploadImageModal.objID = $(this).data('objectid');
        uploadImageModal.objType = $(this).data('objecttype');
        uploadImageModal.imageType = $(this).data('imagetype');
        if($(this).data('objectimagetype')) {
          uploadImageModal.objectImageType = $(this).data('objectimagetype');
        }
        if($(this).data('updatetarget')) {
          uploadImageModal.updateTarget = $(this).data('updatetarget');
        }
        uploadImageModal.imageID = false;
        uploadImageModal.uploadedImageUrl = false;
        uploadImageModal.sizes = {};
        if(uploadImageModal.cropper) {
          uploadImageModal.cropper.destroy();
        }
        
        $.ajax('/image/type/' + uploadImageModal.imageType, {
          complete: function(xhr, responseText) {
            $('#addImageModal .image_type_text').html(xhr.responseJSON.type);
            uploadImageModal.sizes = xhr.responseJSON;
            $('#addImageModal .step-1, #addImageModal #addImageModal-upload').show();
            $('#addImageModal .step-2, #addImageModal #addImageModal-crop').hide();
            $('#addImageModal .spinner').hide();
            $('#addImageModal').modal('show');
          }
        });  
    });
    
    // upload 
    $('#addImageModal-upload').on('click', function(e) {
      $('#addImageModal .spinner').show();
      var data = new FormData();
      $.each($('#addImageModal-file')[0].files, function(i, file) {
          data.append('images', file);
      });
      data.append('type_id', uploadImageModal.imageType);
      
      $.ajax({
        url: '/image/upload',
        data: data,
        cache: false,
        contentType: false,
        processData: false,
        type: 'POST',
        success: function(xhr){
          $('#addImageModal .spinner').hide();
          // console.log(xhr);
          uploadImageModal.uploadedImageUrl = xhr.url;
          uploadImageModal.imageID = xhr.uniqueID;
          $('#addImageModal #imageToBeCropped').attr('src', uploadImageModal.uploadedImageUrl);
          $('#addImageModal .step-1, #addImageModal #addImageModal-upload').hide();
          $('#addImageModal .step-2, #addImageModal #addImageModal-crop').show();
          var image = document.getElementById('imageToBeCropped');
          uploadImageModal.cropper = new Cropper(image, {
            aspectRatio: uploadImageModal.sizes.res_x / uploadImageModal.sizes.res_y,
            autoCropArea: 1,
            zoomable: true
          });
        }
      });
    });
    
    // crop
    $('#addImageModal-crop').on('click', function(e) {
      uploadImageModal.cropper.getCroppedCanvas({width: uploadImageModal.sizes.res_x, height: uploadImageModal.sizes.res_y, fillColor: 'transparent' }).toBlob(function (blob) {
      var formData = new FormData();

      formData.append('images', blob);
      formData.append('image_id', uploadImageModal.imageID);
      formData.append('object_id', uploadImageModal.objID);
      formData.append('object_type', uploadImageModal.objType);
      if(uploadImageModal.objectImageType) {
        formData.append('object_image_type', uploadImageModal.objectImageType);
      }
      
      $.ajax('/image/crop', {
        method: "POST",
        data: formData,
        processData: false,
        contentType: false,
        success: function (xhr) {
          console.log(xhr);
          if(uploadImageModal.updateTarget) {
            setTimeout( function() {
              $(uploadImageModal.updateTarget).attr('src', xhr.url);
            }, 3000);
          }
          uploadImageModal.objID = false;
          uploadImageModal.objType = false;
          uploadImageModal.imageType = false;
          uploadImageModal.imageID = false;
          uploadImageModal.uploadedImageUrl = false;
          uploadImageModal.objectImageType = false;
          uploadImageModal.sizes = {};
          if(uploadImageModal.cropper) {
            uploadImageModal.cropper.destroy();
          }
          $('#addImageModal-file').val('');
          $('#addImageModal #imageToBeCropped').attr('src', '');
          $('#addImageModal').modal('hide');
        },
        error: function () {
          console.log('Upload error');
        }
      });
    });
    });
      
  }
}

module.exports = uploadImageModal;