module.exports = {
  init: function() {
    $('#addCatalogModal-add, #addCollectionModal-add').on('click', function(e) {
      var which = $(this).attr('id'), formName = "", data = {}, url = '', base = '';
      switch(which) {
        case 'addCatalogModal-add' :
          formName = 'addCatalogModal-form';
          url = "/catalog/add";
          base = 'catalog';
          data = {
            catalog_name: $('#addCatalogModal-catalogName').val(),
            subdomain: $('#addCatalogModal-subdomain').val()
          }
          break;
        default:
          formName = 'addCollectionModal-form';
          url = "/collection/add";
          base = 'collection';
          data = {
            catalog_name: $('#addCollectionModal-catalogName').val(),
          }
      }
      e.preventDefault();
      var validator = $('#' + formName).validate({
        
      });
      if(validator.form()) {
        $.ajax({
          url: url,
          data: data,
          type: 'POST',
          success: function(data){
            if(data.errors) {
              var errorHtml = '<ul>';
              for(var i = 0; i < data.errors.length; i++) {
                errorHtml += '<li>' + data.errors[i] + '</li>';
              }
              $('#addCatalogModal-error').html(errorHtml + '</ul>');
              $('#addCatalogModal-error').css('display', 'block');
            } else {
              $('#addCatalogModal-error').css('display', 'none');
              $('#' + formName)[0].reset(); 
              // console.log(data);
              window.location = '/'+base+'/' + data.catalogID ;
            }
          }
        });
      }
    });
  
  }
}