var formPageController = {
  init: function() {
    $('body').on('click', '.master-owner-show-more', function(e) {
      $(this).hide();
      $('.master-owner-extra').show();
    });
    $('body').on('click', '.publisher-show-more', function(e) {
      $(this).hide();
      $('.publisher-extra').show();
    });
    $('body').on('click', '.writer-show-more', function(e) {
      $(this).hide();
      $('.writer-extra').show();
    });
    $('body').on('click', '.add-writer', function(e) {
      e.preventDefault();
      window.modals['addWriter'].initVars({updateItem: $(this).closest('.form-group').find('select')});
      window.openModal('#addWriterModal');
    });
    $('body').on('click', '.add-publisher', function(e) {
      e.preventDefault();
      window.modals['addPublisher'].initVars({updateItem: $(this).closest('.form-group').find('select')});
      window.openModal('#addPublisherModal');
    });
    $('body').on('keypress', '.form-container input.form-control', function(e) {
      
      var keycode = (e.keyCode ? e.keyCode : e.which);
      if(keycode == '13'){
          e.preventDefault();
          $(this).trigger('change');
      }
    })
    formPageController.onload();  
  },
  onload: function() {
    window.initForms();
    if(document.location.href.indexOf('/opportunities/editResponse') > -1) {
      $('.breadcrumb li:nth-child(2) a').attr('href', $('#request-container').data('requesturl'));
      $('.breadcrumb li:nth-child(2) a').html($('#request-container').data('requestname'));
    }
  }
}
module.exports = formPageController;