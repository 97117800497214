// $(document).ready(function(){
var playerControls = {
  wavesurfer: false,
  audioLength: 0,
  audioCurrent: 0,
  isIOS: false,
  loadingProgress: 0,
  playerID: '#player',
  checkPlayingTrack: function() {
    var player = JSON.parse(window.localStorage.player);
    if(player.currentTrack !== false && player.playlist.length > player.currentTrack) {
      $('.play-track[data-track="'+player.playlist[player.currentTrack]+'"]').closest('tr').addClass('active');
      $('.play-track[data-track="'+player.playlist[player.currentTrack]+'"]').closest('li').addClass('active');
    }
    if(player.playing) {
      // $('.play-track[data-track="'+player.playlist[player.currentTrack]+'"]').addClass('pause-track');
    }
  },
  loadAndPlay: function(index) {
    if(!index) {
      index = 0;
    }
    $('.playlist-track').removeClass('active');
    var player = JSON.parse(window.localStorage.player);
    // playerControls.wavesurfer.load(document.location.origin + '/track/listen/580da2d1d3a4b78367f7aff207ff2011d5e583542de2deabc647c81df42d3e58', null, 'none');
    // playerControls.wavesurfer.play();
    if(index >= 0 && player.playlist.length > index) {
      $(playerControls.playerID).addClass('visible');
      var trackID = player.playlist[index];
      $.ajax('/track/info/' + trackID, {
        complete: function(xhr) {
          var track = xhr.responseJSON;
          $.getJSON("/track/waveform/" + trackID, function(waveformJson) {
            // set the track to show it's playing
            $('.playlist-track').removeClass('active');
            $('.play-track[data-track="'+trackID+'"]').closest('.playlist-track').addClass('active');
            if(player.album_id) {
              $('.play-album[data-album="'+player.album_id+'"]').closest('.playlist-track').addClass('active');
            }
            if(player.playlist_id) {
              $('.play-playlist[data-playlist="'+player.playlist_id+'"]').closest('.playlist-track').addClass('active');
            }
            
            player.currentTrack = index;
            
            if(playerControls.wavesurfer.isPlaying()) {
              playerControls.wavesurfer.pause();
            }
            
            $('.pause-track').removeClass('pause-track');
            try {
              // let mediaElt = document.getElementById('ios-audio-player').src = track.stream
              // playerControls.wavesurfer.load(mediaElt, waveformJson.data); 
              $.ajax('/track/listen/' + track.trackID, { success: function(whatevs) {
                
              }})
              if(window.jsFiles.player.isIOS) {
                // const audio = new Audio(track.stream);
                // playerControls.wavesurfer.destroy();
                // $('#player-waveform audio').attr('src', 'data:audio/wav;base64,UklGRiQAAABXQVZFZm10IBAAAAABAAEAVFYAAFRWAAABAAgAZGF0YQAAAAA=');
                playerControls.copyWaveform(playerControls.wavesurfer, function() {
                  $('#player-waveform').addClass('minimized');
                  $('#player-waveform audio').attr('src', '');
                  setTimeout(function() {
                    playerControls.wavesurfer.load(track.stream, waveformJson.data);
                    window.jsFiles.player.loadingProgress = 0;
                    playerControls.wavesurfer.play();
                  },  250)
                });
              } else {
                // playerControls.wavesurfer.destroy();
                playerControls.copyWaveform(playerControls.wavesurfer, function() {
                  $('#player-waveform').addClass('minimized');
                  $('#player-waveform audio').attr('src', '');
                  playerControls.wavesurfer.load(track.stream, waveformJson.data, 'auto');
                  
                });
              }
            } catch(e) {
              console.log(e);
              alert('This track is still transcoding. Please try again later.');
            }
            $(playerControls.playerID + ' .track-details h5').html(track.name + '<span> - '+ track.artist + '</span>');
            $(playerControls.playerID + ' .track-details p').html(track.artist );
            // $('#jp_container_N .jp-play-bar').css('width', '0');
            player.playing = true;
            window.localStorage.player = JSON.stringify(player);   
          });    
          
        }
        
      });
    }
  },
  copyWaveform: function(wavesurfer, callback) {
    if($('#player-waveform > wave > canvas').length > 0) {
      $('#player-waveform-bg').html('<img src="'+wavesurfer.exportImage('png')+'" />');
      $('#player-waveform-bg').css('left', $('#player-waveform').position().left+ 'px')
      callback();
    } else {
      callback();
    }
  },
  animateWaveform: function() {
    $('#player-waveform').removeClass('minimized');
    $('#player-waveform-bg').addClass('minimized');
    setTimeout(function() {
      $('#player-waveform-bg').html('');
      $('#player-waveform-bg').removeClass('minimized');
    }, 500)
  },
  nextTrack: function(stopOnEnd, clicked) {
    if(stopOnEnd === undefined) {
      stopOnEnd = false;
    }
    if(clicked === undefined) {
      clicked = false;
    }
    var keepPlaying = true;
    var player = JSON.parse(window.localStorage.player);
    if(player.playlist.length > 0) {
      player.currentTrack++;
      if(player.currentTrack == player.playlist.length) {
        if(stopOnEnd) {
          keepPlaying = false;
          $('#jp_container_N .jp-pause').hide();
          $('#jp_container_N .jp-play').show();
          player.playing = false;
        } else {
          player.currentTrack = 0;
        }
      }
      if(keepPlaying) {
        this.loadAndPlay(player.currentTrack);
        if(clicked && $('i.play-track[data-track="'+player.playlist[player.currentTrack]+'"]').length > 0) {
          $('html, body').animate({
            scrollTop: $('i.play-track[data-track="'+player.playlist[player.currentTrack]+'"]').offset().top - 100
            }, 250);
        }  
      }
    }
    window.localStorage.player = JSON.stringify(player);
  },
  prevTrack: function(clicked) {
    if(clicked === undefined) {
      clicked = false;
    }
    var player = JSON.parse(window.localStorage.player);
    if(player.playlist.length > 0) {
      if(this.wavesurfer.getCurrentTime() > 5) {
        this.loadAndPlay(player.currentTrack);
      } else {
        player.currentTrack--;
        if(player.currentTrack == -1) {
          player.currentTrack = player.playlist.length - 1;
        }
        this.loadAndPlay(player.currentTrack);
        if(clicked && $('i.play-track[data-track="'+player.playlist[player.currentTrack]+'"]').length > 0) {
          $('html, body').animate({
            scrollTop: $('i.play-track[data-track="'+player.playlist[player.currentTrack]+'"]').offset().top - 100
            }, 250);
        }  
      }
    }
  },
  setMute: function(status) {
    var player = JSON.parse(window.localStorage.player);
    player.mute = status;
    // console.log(status)
    if(status == true) {
      $('#volume-control').attr('src', '/assets/img/whiteicons/icon.mute.png');
      // $('#jp_container_N .jp-mute').hide();
      // $('#jp_container_N .jp-unmute').show();
      this.wavesurfer.setVolume(0);
    } else {
      $('#volume-control').attr('src', '/assets/img/whiteicons/icon.volume.png');
      // $('#jp_container_N .jp-unmute').hide();
      // $('#jp_container_N .jp-mute').show();
      this.wavesurfer.setVolume(player.volume);
    }
    window.localStorage.player = JSON.stringify(player);
  },
  setVolume: function(level) {
    var player = JSON.parse(window.localStorage.player);
    level = parseFloat(level);
    player.volume = level;
    if(!player.mute) {
      this.wavesurfer.setVolume(level);
    }
    $('.volume-bar .current').css('height', (100 * level) +'%');
    // $('#jp_container_N .jp-volume-bar-value').css('width', (level * 100) + '%');
    window.localStorage.player = JSON.stringify(player);
  },
  initializePlayer: function() {
    var player = JSON.parse(window.localStorage.player);
    
    if(document.location.hash == '#logout') {
      player.playing = false;
      player.playlist = [];
      window.localStorage.player = JSON.stringify(player);
    }
    
    if(player.playing == false) {
      $(playerControls.playerID).removeClass('visible');
    }
    
    this.setMute(player.mute);
    this.setVolume(player.volume);
    if(player.currentTrack < player.playlist.length && player.playing) {
      this.loadAndPlay(player.currentTrack);  
    }
  },
  playTrackHandler: function(e, thisObj) {
    e.preventDefault();
    e.stopPropagation();
    var tempTrackID = $(thisObj).data('track') ? $(thisObj).data('track') : $(thisObj).data('objectid');
    var player = JSON.parse(window.localStorage.player);
    if($(thisObj).hasClass('pause-track')) {
      $('#pause-control').trigger('click');
    } else {
      if(player.playlist[player.currentTrack] == tempTrackID) {
        if(!$(playerControls.playerID).hasClass('visible')) {
          playerControls.loadAndPlay(player.currentTrack);  
        } else {
          $('#play-control').trigger('click');
        }
      } else {
        var playerTrackID = $(thisObj).data('track');
    
        // empty out the current playlist
        player.album_id = false;
        player.playlist_id = false;
        player.playlist = [];
        
        // get all of the tracks on this current playlist
        var currentIndex = 0;
        var domTracks = $(thisObj).closest('.playlist-holder').find('.playlist-track .play-track:not(.disabled)');
        if(domTracks.length == 0) {
          currentIndex = 0;
          player.playlist.push(playerTrackID);
        } else {
          for(var i = 0; i < domTracks.length; i++) {
            player.playlist.push($(domTracks[i]).data('track') ? $(domTracks[i]).data('track') : $(domTracks[i]).data('objectid'));
            if($(domTracks[i]).data('track') == playerTrackID) {
              currentIndex = i;
            }
          }
        }
        window.localStorage.player = JSON.stringify(player);
        playerControls.loadAndPlay(currentIndex);
           
      } 
    }
  },
  initializeWebsurfer: function(AudioContext, callback) {
    if(window.jsFiles.player.wavesurfer == false) {
      var myContext= new AudioContext()
      if(window.jsFiles.player.isIOS) {
        // create empty buffer
      	var buffer = myContext.createBuffer(1, 1, 22050);
      	var source = myContext.createBufferSource();
      	source.buffer = buffer;
      
      	// connect to output (your speakers)
      	source.connect(myContext.destination);
      
      	// play the file
      	source.start();   
      }
      window.jsFiles.player.wavesurfer = WaveSurfer.create({
        container: '#player-waveform',
        barWidth: 3,
        barRadius: true,
        waveColor: '#26213C',
        cursorColor: '#4D46CD',
        progressColor: '#f8525b',
        closeAudioContext: true,
        backend: window.jsFiles.player.isIOS ? 'WebAudio' : 'MediaElement',
        // backend: 'WebAudio',
        audioContext: myContext,
        normalize: true,
      });
      $(window).on('resize', function() {
        window.jsFiles.player.wavesurfer.drawBuffer();
      }) 
      window.jsFiles.player.wavesurfer.on('finish', function () {
       // $('#jp_container_N .spinner').hide();
        window.jsFiles.player.nextTrack(true);
      });
      window.jsFiles.player.wavesurfer.on('pause', function () {
        // $('#jp_container_N .spinner').hide();
        $('#pause-control').hide();
        $('#play-control').show();
        var player = JSON.parse(window.localStorage.player);
        $('.play-track[data-track="'+player.playlist[player.currentTrack]+'"]').removeClass('pause-track');
        if(player.album_id) {
          $('.play-album[data-album="'+player.album_id+'"]').removeClass('pause-track');
        }
        if(player.playlist_id) {
          $('.play-playlist[data-playlist="'+player.playlist_id+'"]').removeClass('pause-track');
        }
        player.playing = false;
        window.localStorage.player = JSON.stringify(player);
      });
      window.jsFiles.player.wavesurfer.on('loading', function (percent) {
        // $('#jp_container_N .spinner').hide();
        window.jsFiles.player.loadingProgress = percent;
      });
      window.jsFiles.player.wavesurfer.on('play', function () {
        // $('#jp_container_N .spinner').hide();
        if(!window.jsFiles.player.isIOS || (window.jsFiles.player.isIOS && window.jsFiles.player.loadingProgress == 100)) {
          $('#play-control').hide();
          $('#pause-control').show();
          var player = JSON.parse(window.localStorage.player);
          $('.play-track[data-track="'+player.playlist[player.currentTrack]+'"]').addClass('pause-track');
          if(player.album_id) {
            $('.play-album[data-album="'+player.album_id+'"]').addClass('pause-track');
          }
          if(player.playlist_id) {
            $('.play-playlist[data-playlist="'+player.playlist_id+'"]').addClass('pause-track');
          }
          player.playing = true;
          window.localStorage.player = JSON.stringify(player);
        } else {
          window.jsFiles.player.wavesurfer.pause();
        }
      });
      window.jsFiles.player.wavesurfer.on('audioprocess', function(e) {
        if(Math.floor(e * 10) != window.jsFiles.player.audioCurrent) {
          window.jsFiles.player.audioCurrent = Math.floor(e * 10);
          $('#current-time').html(Math.floor(Math.floor(e) / 60) +':' + ('00' + (Math.floor(e) % 60)).slice(-2) ); 
          // $('#jp_container_N .jp-play-bar').css('width', ((e / thisObj.audioLength) *100) + '%');
        }
      }); 
      window.jsFiles.player.initializePlayer();
      window.jsFiles.player.wavesurfer.on('ready', function(e) {
        var player = JSON.parse(window.localStorage.player);
        window.jsFiles.player.audioLength = window.jsFiles.player.wavesurfer.getDuration();
        if(player.mute) {
          window.jsFiles.player.wavesurfer.setVolume(0);
        } else {
          window.jsFiles.player.wavesurfer.setVolume(player.volume);
        }
        // window.jsFiles.player.wavesurfer.play();
        
        const playPromise = Promise.resolve(window.jsFiles.player.wavesurfer.play());
        
        playPromise
        .then(() => {
          console.log('Promise kept!');
        	// window.jsFiles.player.wavesurfer.play();
        })
        .catch(e => {
          console.log('Promise exception', e);
        });
        window.jsFiles.player.audioCurrent = 0;
        // console.log(thisObj.audioLength);
        $('#total-time').html(Math.floor(window.jsFiles.player.audioLength / 60) +':' + ('00' + (Math.floor(window.jsFiles.player.audioLength) % 60)).slice(-2) );
        playerControls.animateWaveform();
      });
      callback();
    } else {
      callback();
    }
  },
  init: function() {
    var thisObj = this;
    $(playerControls.playerID).removeClass('visible');
    
    const audioCtx = new (window.AudioContext || window.webkitAudioContext)();
    window.jsFiles.player.webAudioTouchUnlock(audioCtx);
    window.jsFiles.player.isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
      
    /*
    $('#ios-audio-player')[0].addEventListener('canplay', function() {
      var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
      if(iOS) {
        window.jsFiles.player.wavesurfer.play();
      }
    }, false);
    */
    
    var playerTrackID = false;
    
    $('body').on('click', '.play-track:not(.disabled)', function(e) {
      /*
      var playInterval = setInterval(function() {
        if(!playerControls.wavesurfer.isPlaying()) {
          // playerControls.wavesurfer.play();
          const playPromise = Promise.resolve(playerControls.wavesurfer.play());
          
          playPromise.then(() => {
          	playerControls.wavesurfer.play();
          }).catch(e => {
          });
          clearInterval(playInterval);
        }
      }, 1000);
      */
      if(e.handled === false) return
      e.stopPropagation();
      e.preventDefault();
      e.handled = true;
      
      var localThis = this;
      thisObj.initializeWebsurfer(window.AudioContext || window.webkitAudioContext, function() {
        thisObj.playTrackHandler(e, localThis);  
      });
      
    });
    
    $('body').on('click', '.play-album:not(.disabled)', function(e) {
      if(e.handled === false) return
      e.stopPropagation();
      e.preventDefault();
      e.handled = true;
      
      var $this = $(this);
      
      thisObj.initializeWebsurfer(window.AudioContext || window.webkitAudioContext, function() {
        var tempAlbumID = $this.data('album');
        var player = JSON.parse(window.localStorage.player);
        if($this.hasClass('pause-track')) {
          $('#pause-control').trigger('click');
        } else {
          if(player.album_id == tempAlbumID) {
           if($(playerControls.playerID).css('display') == 'none') {
              thisObj.loadAndPlay(player.currentTrack);  
            } else {
              $('#play-control').trigger('click');
            }
          } else {
            // empty out the current playlist
            player.album_id = tempAlbumID;
            player.playlist_id = false;
            player.playlist = [];
            
            $.ajax('/album/listTracks/' + tempAlbumID, {
              success: function(data) {
                // get all of the tracks on this current playlist
                var currentIndex = 0;
                for(var i = 0; i < data.length; i++) {
                  if(!data[i].disabled) {
                    player.playlist.push(data[i].object_id);
                  }
                }
                window.localStorage.player = JSON.stringify(player);
                thisObj.loadAndPlay(currentIndex);
              }
            })
          }
        }
      });
      
    });
    
    $('body').on('click', '.play-playlist:not(.disabled)', function(e) {
      if(e.handled === false) return
      e.stopPropagation();
      e.preventDefault();
      e.handled = true;
      
      var $this = $(this);
      
      thisObj.initializeWebsurfer(window.AudioContext || window.webkitAudioContext, function() {
        var tempPlaylistID = $this.data('playlist');
        var player = JSON.parse(window.localStorage.player);
        if($this.hasClass('pause-track')) {
          $('#pause-control').trigger('click');
        } else { 
          if(player.playlist_id == tempPlaylistID) {
            if($(playerControls.playerID).css('display') == 'none') {
              thisObj.loadAndPlay(player.currentTrack);  
            } else {
              $('#play-control').trigger('click');
            }
          } else {
            // empty out the current playlist
            player.album_id = false;
            player.playlist_id = tempPlaylistID;
            player.playlist = [];
            
            $.ajax('/playlist/getPlayableTracks/' + tempPlaylistID, {
              success: function(data) {
                // get all of the tracks on this current playlist
                if(Array.isArray(data)) {
                  var currentIndex = 0;
                  player.playlist = data;
                  window.localStorage.player = JSON.stringify(player);
                  thisObj.loadAndPlay(currentIndex);  
                }
              }
            })
          }
        }
      });
      
    });
    
    // update the volume
    var volumeTimeout = false;
    var volumeOpen = false;
    $('.volume-container').on('click', function(e) {
      thisObj.initializeWebsurfer(window.AudioContext || window.webkitAudioContext, function() {
        if(volumeTimeout) {
          clearTimeout(volumeTimeout);
          volumeTimeout = false;
        }
        $('.volume-bar').css('display', 'block');
        $('.volume-bar').css('opacity', 1);
        volumeOpen = true;  
      });
    });
    $('#volume-control').on('click', function(e) {
      thisObj.initializeWebsurfer(window.AudioContext || window.webkitAudioContext, function() {
        if($(this).attr('src') == '/assets/img/whiteicons/icon.mute.png') {
          thisObj.setMute(false);
          e.preventDefault();
        } else {
          if(volumeOpen) {
            if($(this).attr('src') == '/assets/img/whiteicons/icon.volume.png') {
              thisObj.setMute(true);
              $('.volume-bar').css('display', 'none');
              volumeOpen = false;
            }
          }
        }
      });
    });
    $('.volume-container').on('mouseover', function(e) {
      thisObj.initializeWebsurfer(window.AudioContext || window.webkitAudioContext, function() {
        if(volumeTimeout) {
          clearTimeout(volumeTimeout);
          volumeTimeout = false;
        }
      });
    });
    $('.volume-container').on('mouseout', function(e) {
      thisObj.initializeWebsurfer(window.AudioContext || window.webkitAudioContext, function() {
        volumeTimeout = setTimeout(function() {
          $('.volume-bar').css('opacity', 0);
          volumeOpen = false;
          setTimeout(function() {
            $('.volume-bar').css('display', 'none');
          }, 700);
          
        }, 1000);
      });
    });
    $('.volume-bar').on('click', function(e) {
      let $this = $(this);
      thisObj.initializeWebsurfer(window.AudioContext || window.webkitAudioContext, function() {
        var posX = $this.offset().left, posY = $this.offset().top;
        if((e.pageY - posY) >= 12 ){
          var volume = (1 - ((e.pageY - posY -12) / 190));
          thisObj.setVolume(volume);
        }    
      });
      
    });
    
    $('body').on('click','#play-control', function(e) {
      if(e.handled === false) return
      e.stopPropagation();
      e.preventDefault();
      e.handled = true;
      
      thisObj.initializeWebsurfer(window.AudioContext || window.webkitAudioContext, function() {
        try {
          thisObj.wavesurfer.play(); 
        } catch(e) {
          var player = JSON.parse(window.localStorage.player);
          thisObj.loadAndPlay(player.currentTrack);
        }
      }); 
      /*
      const playPromise = Promise.resolve(thisObj.wavesurfer.play());
      
      playPromise
      .then(() => {
      	thisObj.wavesurfer.play();
      })
      .catch(e => {
      });
      */
      
    });
    $('body').on('click', '#pause-control', function(e) {
      if(e.handled === false) return
      e.stopPropagation();
      e.preventDefault();
      e.handled = true; 
      
      thisObj.initializeWebsurfer(window.AudioContext || window.webkitAudioContext, function() {
        thisObj.wavesurfer.pause();
      });
    });
    $('#next-control').on('click', function(e) {
      if(e.handled === false) return
      e.stopPropagation();
      e.preventDefault();
      e.handled = true;
      
      thisObj.initializeWebsurfer(window.AudioContext || window.webkitAudioContext, function() {
        thisObj.nextTrack(undefined, true);
      });
    });
    $('#prev-control').on('click', function(e) {
      if(e.handled === false) return
      e.stopPropagation();
      e.preventDefault();
      e.handled = true;
      
      thisObj.initializeWebsurfer(window.AudioContext || window.webkitAudioContext, function() {
        thisObj.prevTrack(true);
      });
      
    });
    $('#jp_container_N .jp-volume-bar').on('click', function(e) {
      let $this = $(this);
      thisObj.initializeWebsurfer(window.AudioContext || window.webkitAudioContext, function() {
        var test = (e.clientX-$this.offset().left) / $this.outerWidth();
        thisObj.setVolume(test);
      });
      
    });
    
    // initialize pending downloads
    $('#pending-downloads h5 span').on('click', function(e) {
      e.preventDefault();
      $('#pending-downloads').toggleClass('minimized');
    })
    
  },
  webAudioTouchUnlock: function(context) { 
    if (context.state !== 'suspended') return;
    const b = document.body;
    const events = ['touchstart','touchend', 'mousedown','mouseup', 'click'];
    events.forEach(e => b.addEventListener(e, unlock, false));
    function unlock() { context.resume().then(clean); }
    function clean() { events.forEach(e => b.removeEventListener(e, unlock)); }
  },
};

module.exports = playerControls;